import { UserGroupIcon, RectangleStackIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { DailyUsageRedis, LiveUsageRedis } from '../../../../common/constant'
import { sumDailyCr, sumDailyUs } from '../../../utilities'

interface Card {
    name: string
    icon: any
    amount: number
}
const renderCard = (card: Card) => {
    return (
        <div
            key={card.name}
            className="overflow-hidden rounded-lg bg-white shadow"
        >
            <div className="p-5">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <card.icon
                            className="h-6 w-6 text-gray-400"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="ml-5 w-0 flex-1">
                        <dl>
                            <dt className="truncate text-sm font-medium text-gray-500">
                                {card.name}
                            </dt>
                            <dd>
                                <div className="text-lg font-medium text-gray-900">
                                    {card.amount}
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface Props {
    dataType: 'LiveUsageRedis' | 'DailyUsageRedis'
    cardsData: LiveUsageRedis[] | DailyUsageRedis[]
}
const HistoryCards: React.FC<Props> = ({ dataType, cardsData }) => {
    const { t } = useTranslation()
    const getTotalUsersNbr = () => {
        let totalUsers = 0
        switch (dataType) {
            case 'LiveUsageRedis':
                totalUsers =
                    cardsData.length > 0
                        ? Math.max(
                              ...cardsData.map(function (el: any) {
                                  return el.us
                              })
                          )
                        : 0
                break

            case 'DailyUsageRedis':
                totalUsers =
                    cardsData.length > 0
                        ? sumDailyUs(cardsData as DailyUsageRedis[])
                        : 0
                break

            default:
                break
        }

        return totalUsers
    }

    const getCreatedRoomNbr = () => {
        let createdRooms = 0
        switch (dataType) {
            case 'LiveUsageRedis':
                createdRooms =
                    cardsData.length > 0
                        ? Math.max(
                              ...cardsData.map(function (el: any) {
                                  return el.cr
                              })
                          )
                        : 0
                break
            case 'DailyUsageRedis':
                createdRooms =
                    cardsData.length > 0
                        ? sumDailyCr(cardsData as DailyUsageRedis[])
                        : 0
                break
            default:
                break
        }
        return createdRooms
    }

    const cards = [
        {
            name: t('Total users'),
            icon: UserGroupIcon,
            amount: getTotalUsersNbr(),
        },

        {
            name: t('Created rooms'),
            icon: RectangleStackIcon,
            amount: getCreatedRoomNbr(),
        },
    ]

    return <> {cards.map((c) => renderCard(c))}</>
}

export default HistoryCards
