import { gql } from '@apollo/client'
import { loginFragment } from './fragments'

/**
 * QUERIES
 */

export const LOGINS_QUERY = gql`
    query LoginsData {
        logins {
            ...LoginDetail
        }
    }
    ${loginFragment}
`

export const LOGIN_QUERY = gql`
    query LoginData($id: String) {
        login(where: { id: $id }) {
            ...LoginDetail
        }
    }
    ${loginFragment}
`

/**
 * MUTATIONS
 */

export const LOGIN_CREATE = gql`
    mutation CreateOneLogin($data: LoginCreateInput!) {
        login: createOneLogin(data: $data) {
            ...LoginDetail
        }
    }

    ${loginFragment}
`

export const LOGIN_UPDATE = gql`
    mutation UpdateOneLogin(
        $data: LoginUpdateInput!
        $where: LoginWhereUniqueInput!
    ) {
        login: updateOneLogin(data: $data, where: $where) {
            ...LoginDetail
        }
    }
    ${loginFragment}
`

export const LOGIN_DELETE = gql`
    mutation DeleteOneLogin($where: LoginWhereUniqueInput!) {
        login: deleteOneLogin(where: $where) {
            id
        }
    }
`

/**
 * SUBSCRIPTIONS
 */
export const LOGIN_UPDATE_SUBSCRIPTION = gql`
    subscription LoginUpdateDataChange($userId: ID!) {
        onLoginUpdate(userId: $userId) {
            ...LoginDetail
        }
    }
    ${loginFragment}
`

export const LOGIN_CREATE_SUBSCRIPTION = gql`
    subscription LoginCreateDataChange($userId: ID!) {
        onLoginCreate(userId: $userId) {
            ...LoginDetail
        }
    }
    ${loginFragment}
`

export const LOGIN_DELETE_SUBSCRIPTION = gql`
    subscription LoginDeleteDataChange($userId: ID!) {
        onLoginDelete(userId: $userId) {
            ...LoginDetail
        }
    }
    ${loginFragment}
`
