import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import {
    RectangleStackIcon,
    ArrowTrendingUpIcon,
    UsersIcon,
} from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DailyUsageRedis } from '../../../common/constant'
import { useZsDailyData } from '../../../zustand-store'
import {
    AggregationType,
    avgDailyAU,
    mxDailyAU,
    sumDailyCr,
    sumDailyUs,
} from '../../utilities'

interface IStats {
    stat: DailyUsageRedis[]
    statPrv: DailyUsageRedis[]
}

type ChangeType = 'increase' | 'decrease' | 'same'

interface DisplayStats {
    id: number
    name: string
    stat: number | string
    icon: any
    change: string
    changeType: ChangeType
}

const generateStats = (stats: IStats): DisplayStats[] => {
    let displayStats: DisplayStats[] = []

    const us = sumDailyUs(stats.stat)
    const usPrev = sumDailyUs(stats.statPrv)
    let changeType: ChangeType = 'same'

    if (us > usPrev) {
        changeType = 'increase'
    } else if (us < usPrev) {
        changeType = 'decrease'
    }

    const userStats = {
        id: 1,
        name: 'Total users',
        stat: us,
        icon: UsersIcon,
        change: String(Math.abs(us - usPrev)),
        changeType: changeType,
    }
    displayStats.push(userStats)

    const cr = sumDailyCr(stats.stat)
    const crPrev = sumDailyCr(stats.statPrv)

    const diffCr = Math.abs(cr - crPrev)

    let changeTypeCr: ChangeType = 'same'
    if (Math.ceil(diffCr)) {
        if (cr > crPrev) {
            changeTypeCr = 'increase'
        } else if (cr < crPrev) {
            changeTypeCr = 'decrease'
        }
    }

    const createdRooms = {
        id: 2,
        name: 'Created rooms',
        stat: Math.ceil(cr),
        icon: RectangleStackIcon,
        change: String(Math.ceil(diffCr)),
        changeType: changeTypeCr,
    }

    displayStats.push(createdRooms)

    const max = mxDailyAU(stats.stat)
    const maxPrev = mxDailyAU(stats.statPrv)

    let changeTypeMx: ChangeType = 'same'

    const diffMx = Math.abs(max - maxPrev)

    if (Math.ceil(diffMx)) {
        if (max > maxPrev) {
            changeTypeMx = 'increase'
        } else if (max < maxPrev) {
            changeTypeMx = 'decrease'
        }
    }

    const maxUsers = {
        id: 3,
        name: 'Max. live users',
        stat: Math.ceil(max),
        icon: ArrowTrendingUpIcon,
        change: String(Math.ceil(diffMx)),
        changeType: changeTypeMx,
    }

    displayStats.push(maxUsers)

    return displayStats
}
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

interface Props {
    description: string
    aggregationType: AggregationType
}
const Stat: React.FC<Props> = ({ aggregationType, description }) => {
    const { t } = useTranslation()
    const allDailyData = useZsDailyData()

    const [statsData, setStatsData] = useState<IStats>({
        stat: [],
        statPrv: [],
    })

    const stats = generateStats(statsData)

    useEffect(() => {
        let gap = 0
        let gapPrev = 0
        switch (aggregationType) {
            case 'daily':
                gap = 1
                gapPrev = 2
                break
            case 'weekly':
                gap = 7
                gapPrev = 13
                break
            case 'monthly':
                gap = 30
                gapPrev = 59
                break
            default:
                break
        }

        if (allDailyData) {
            const _stat = allDailyData.filter((d) =>
                moment(d.ts)
                    .subtract(3, 'second')
                    .isBetween(
                        moment().subtract(gap, 'day'),
                        moment().subtract(1, 'day'),
                        'day',
                        '[]'
                    )
            )
            const _statsPrev = allDailyData.filter((d) =>
                moment(d.ts)
                    .subtract(3, 'second')
                    .isBetween(
                        moment().subtract(gapPrev, 'day'),
                        moment().subtract(gap === 1 ? gapPrev : gap, 'day'),
                        'day',
                        '[]'
                    )
            )
            setStatsData({
                stat: _stat,
                statPrv: _statsPrev,
            })
        }
        return () => {
            //
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aggregationType])

    console.log(
        `aggregationType:${aggregationType}  stats:${JSON.stringify(
            statsData,
            null,
            3
        )}:`
    )

    return (
        <div className="mt-8">
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {description}
                </h3>

                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {stats.map((item) => (
                        <div
                            key={item.id}
                            className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
                        >
                            <dt>
                                <div className="absolute rounded-md bg-cyan-500 p-3">
                                    <item.icon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </div>
                                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                                    {t(item.name)}
                                </p>
                            </dt>
                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">
                                    {item.stat}
                                </p>

                                {item.changeType !== 'same' && (
                                    <p
                                        className={classNames(
                                            item.changeType === 'increase'
                                                ? 'text-green-600'
                                                : 'text-red-600',
                                            'ml-2 flex items-baseline text-sm font-semibold'
                                        )}
                                    >
                                        {item.changeType === 'increase' ? (
                                            <ArrowUpIcon
                                                className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <ArrowDownIcon
                                                className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                                                aria-hidden="true"
                                            />
                                        )}

                                        <span className="sr-only">
                                            {' '}
                                            {item.changeType === 'increase'
                                                ? 'Increased'
                                                : 'Decreased'}{' '}
                                            by{' '}
                                        </span>
                                        {item.change}
                                    </p>
                                )}
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}

export default Stat
