import { ApolloProvider } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import { AppLogger } from '../../AppLogger'
import createClient from '../graphql/apolloClient'
import Layout from './Layout'

const logger = AppLogger.getInstance()
export const Main = () => {
    logger.info(`Rendering Dashboard -----------`)
    const [ready, setReady] = useState(false)

    const clientRef = useRef<any>(null)

    useEffect(() => {
        if (!clientRef.current) {
            logger.info(`Dashboard createClient  -----------`)
            clientRef.current = createClient()
        }
        setReady(true)
        return () => {}
    }, [])

    return ready ? (
        <ApolloProvider client={clientRef.current}>
            <Layout />
        </ApolloProvider>
    ) : (
        <></>
    )
}
