import {
    Route,
    BrowserRouter as Router,
    Switch,
    Redirect,
} from 'react-router-dom'
import * as QueryString from 'query-string'
import NotFoundPage from './NotFoundPage'

import { Main } from './main/Main'
import ResetForm from './password-reset/ResetForm'
import { useZsUser } from './zustand-store'
import ChangePasswordForm from './password-reset/ChangePasswordForm'
import ErrorPage from './ErrorPage'
import LoginForm from './LoginForm'
import UnsubscribePage from './Unsubscribe'

const AppRouter = () => {
    const basename = process.env.PUBLIC_URL
    const user = useZsUser()
    return (
        <Router basename={basename}>
            <Switch>
                <Route
                    exact={true}
                    path="/"
                    component={Boolean(user) ? Main : LoginForm}
                />
                <Route
                    exact={true}
                    path="/reset"
                    render={() => {
                        return Boolean(user) ? (
                            <Redirect
                                to={{
                                    pathname: '/',
                                }}
                            />
                        ) : (
                            <ResetForm />
                        )
                    }}
                />
                <Route
                    path="/changePassword"
                    render={(props: any) => {
                        const params = QueryString.parse(props.location.search)
                        const stateResetToken = props.location.state
                            ? props.location.state.resetToken
                            : null

                        const resetToken = stateResetToken
                            ? stateResetToken
                            : params.resetToken

                        return Boolean(user) ? (
                            <Redirect
                                to={{
                                    pathname: '/',
                                }}
                            />
                        ) : resetToken ? (
                            <ChangePasswordForm
                                resetToken={resetToken as string}
                            />
                        ) : (
                            <ErrorPage />
                        )
                    }}
                />

                <Route path="/unsubscribe" component={UnsubscribePage} />
                <Route path="/error" component={ErrorPage} />
                <Route component={NotFoundPage} />
            </Switch>
        </Router>
    )
}

export default AppRouter
