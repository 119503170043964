import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { useEffect, useState } from 'react'
import { AppLogger } from '../../../../AppLogger'

import {
    VersionCreateDataChange,
    VersionCreateDataChangeVariables,
} from '../../../../generated/VersionCreateDataChange'
import {
    VersionDeleteDataChange,
    VersionDeleteDataChangeVariables,
} from '../../../../generated/VersionDeleteDataChange'
import { VersionsData } from '../../../../generated/VersionsData'
import {
    VersionUpdateDataChange,
    VersionUpdateDataChangeVariables,
} from '../../../../generated/VersionUpdateDataChange'

import {
    VERSIONS_QUERY,
    VERSION_CREATE,
    VERSION_CREATE_SUBSCRIPTION,
    VERSION_DELETE,
    VERSION_DELETE_SUBSCRIPTION,
    VERSION_UPDATE,
    VERSION_UPDATE_SUBSCRIPTION,
} from '../../../graphql/queries/version-control'

import {
    useZsVersionAdd,
    useZsVersionUpdate,
    useZsVersionDelete,
    useZsVersionAddAll,
} from '../../../zustand-store'
import { CreateOneVersionControl } from '../../../../generated/CreateOneVersionControl'
import { CreateOneVersionControlVariables } from '../../../../generated/CreateOneVersionControl'
import {
    UpdateOneVersionControl,
    UpdateOneVersionControlVariables,
} from '../../../../generated/UpdateOneVersionControl'
import {
    DeleteOneVersionControl,
    DeleteOneVersionControlVariables,
} from '../../../../generated/DeleteOneVersionControl'
import {
    VersionControlCreateInput,
    VersionControlUpdateInput,
} from '../../../../generated/globalTypes'
import moment from 'moment'

const logger = AppLogger.getInstance()

export function useVersions(userId: string) {
    const [isReady, setReady] = useState(false)

    const zsAddVersions = useZsVersionAddAll()
    const zsAddVersion = useZsVersionAdd()
    const zsUpdateVersion = useZsVersionUpdate()
    const zsDeleteVersion = useZsVersionDelete()

    /** Queries */

    // get all the versions
    const { data, loading } = useQuery<VersionsData>(VERSIONS_QUERY, {
        fetchPolicy: 'network-only',
    })

    /** Mutations */

    const [createVersion, createVersionStatus] = useMutation<
        CreateOneVersionControl,
        CreateOneVersionControlVariables
    >(VERSION_CREATE)

    const [updateVersion, updateVersionStatus] = useMutation<
        UpdateOneVersionControl,
        UpdateOneVersionControlVariables
    >(VERSION_UPDATE)

    const [deleteVersion, deleteVersionStatus] = useMutation<
        DeleteOneVersionControl,
        DeleteOneVersionControlVariables
    >(VERSION_DELETE)

    /**
     * useEffects
     */

    useEffect(() => {
        if (!loading && data) {
            const versions = data.versionControls ? data.versionControls : []

            zsAddVersions(versions)
            setReady(true)
        }
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    /** Subscriptions */
    useSubscription<VersionCreateDataChange, VersionCreateDataChangeVariables>(
        VERSION_CREATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onData: async ({ data }) => {
                const version = data.data?.onVersionCreate
                if (version) {
                    logger.debug(
                        `VERSION_CREATE_SUBSCRIPTION version:${JSON.stringify(
                            version,
                            null,
                            3
                        )}`
                    )
                    zsAddVersion(version)
                }
            },
        }
    )

    useSubscription<VersionUpdateDataChange, VersionUpdateDataChangeVariables>(
        VERSION_UPDATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onData: async ({ data }) => {
                const version = data.data?.onVersionUpdate
                if (version) {
                    logger.debug(
                        `VERSION_UPDATE_SUBSCRIPTION version:${JSON.stringify(
                            version,
                            null,
                            3
                        )}`
                    )
                    zsUpdateVersion(version.id, version)
                }
            },
        }
    )

    useSubscription<VersionDeleteDataChange, VersionDeleteDataChangeVariables>(
        VERSION_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onData: async ({ data }) => {
                const version = data.data?.onVersionDelete
                if (version) {
                    logger.debug(
                        `VERSION_DELETE_SUBSCRIPTION version:${JSON.stringify(
                            version,
                            null,
                            3
                        )}`
                    )
                    zsDeleteVersion(version.id)
                }
            },
        }
    )

    return {
        deleteVersionStatus,
        deleteVersion: (versionId: string) =>
            deleteVersion({
                variables: {
                    where: {
                        id: versionId,
                    },
                },
                update: (_client, { data }) => {
                    const deletedVersion = data?.version?.id
                    if (deletedVersion) {
                        zsDeleteVersion(deletedVersion)
                    }
                },
            }),

        createVersionStatus,
        createVersion: (version: VersionControlCreateInput) =>
            createVersion({
                variables: {
                    data: version,
                },
                update: (_client, { data }) => {
                    const createdVersion = data?.version
                    if (createdVersion) {
                        zsAddVersion(createdVersion)
                    }
                },
            }),

        updateVersionStatus,
        updateVersion: (
            updatedVersion: VersionControlUpdateInput,
            versionId: string
        ) =>
            updateVersion({
                variables: {
                    data: {
                        description: updatedVersion.description,
                    },
                    where: {
                        id: versionId,
                    },
                },
                update: (_client, { data }) => {
                    const updatedVersion = data?.version
                    if (updatedVersion) {
                        zsUpdateVersion(versionId, updatedVersion)
                    }
                },
            }),

        data,
        loading: !isReady,
    }
}
