import { gql } from '@apollo/client'
import {
    dailyUsageFragment,
    instanceUsageFragment,
    liveUsageFragment,
} from './fragments'

/**
 * SUBSCRIPTIONS
 */

export const LIVE_USAGE_CREATE_SUBSCRIPTION = gql`
    subscription UserLiveUsageDataChange($userId: ID!) {
        onLiveUsageCreate(userId: $userId) {
            ...LiveUsageDetail
        }
    }
    ${liveUsageFragment}
`

export const DAILY_USAGE_CREATE_SUBSCRIPTION = gql`
    subscription UserDailyUsageDataChange($userId: ID!) {
        onDailyUsageCreate(userId: $userId) {
            ...DailyUsageDetail
        }
    }
    ${dailyUsageFragment}
`

export const INSTANCE_USAGE_SUBSCRIPTION = gql`
    subscription InstanceUsageDataChange($userId: ID!) {
        onInstanceUsage(userId: $userId) {
            ...InstanceUsageDetail
        }
    }
    ${instanceUsageFragment}
`
