import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { isMobile, isIOS, isAndroid, browserName } from 'react-device-detect'
import {
    InMemoryCache,
    ApolloClient,
    ApolloLink,
    split,
    HttpLink,
} from '@apollo/client'

import { SubscriptionClient } from 'subscriptions-transport-ws'
import { TOKEN_KEY } from '../common/constant'
import { AppLogger } from '../../AppLogger'

const accessTokenKey = TOKEN_KEY

const logger = AppLogger.getInstance()

logger.info(`>>>>>>>> apollo client>>>>>>>>`)

/**
 * return the profile of the end client regardless if it's mobile or web
 * e.g: analytics
 * @returns
 */
const getProfile = () => {
    const profile = 'Analytics'
    return profile
}

/**
 * get the device used
 * e.g : ios, android, and browsername
 */
const getOS = () => {
    let device = browserName
    if (isIOS) {
        device = 'iOS'
    }
    if (isAndroid) {
        device = 'Android'
    }
    return device
}

const getOrigin = () => {
    return isMobile ? 'WM' : 'W'
}

/**
 * When serving app in release mode
 * Determine socket protocol ws or wss based on the window protocol
 */
const getWS_LinkUri = (): string => {
    if (window.location.protocol === 'http:') {
        return `ws://${window.location.host}/analytics/wss`
    }
    return `wss://${window.location.host}/analytics/wss`
}

export const getAccessToken = () => {
    const _token = window.localStorage.getItem(accessTokenKey)
    const _accessToken = _token ? _token : ''
    logger.debug(`getAccessToken:${_accessToken}`)
    return _accessToken
}

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, path }) => {
            logger.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
        })
    }
    if (networkError) {
        logger.error(
            `[Network error ${operation.operationName}]: ${networkError.message}`
        )
    }
})

// for release build REACT_APP_WEBSOCKETLINK_URI should be null
const wsLocalhostLinkUri = process.env['REACT_APP_WEBSOCKETLINK_URI']
let subscriptionClient: SubscriptionClient | undefined = undefined

const closeSubscriptionClient = () => {
    subscriptionClient = undefined
}

const createSubscriptionClient = () => {
    if (subscriptionClient) {
        logger.info(`createSubscriptionClient already created, close it`)
        //subscriptionClient.close(true, true)
        return subscriptionClient
    }
    subscriptionClient = new SubscriptionClient(
        wsLocalhostLinkUri ? wsLocalhostLinkUri : getWS_LinkUri(),
        {
            reconnect: true,
            connectionParams: {
                authToken: getAccessToken(),
                or: getOrigin(),
                th: getProfile(),
                os: getOS(),
            },
            connectionCallback: (error) => {
                if (error) {
                    logger.error(`subscriptionClient error`)
                    logger.error(error)
                } else {
                    logger.info(`subscriptionClient has been initialized`)
                }
            },
        }
    )
    return subscriptionClient
}

const createClient = () => {
    logger.info(`>>>>>>>> createClient >>>>>>>>>>`)
    const cache = new InMemoryCache()

    const httpLink = new HttpLink({
        uri: wsLocalhostLinkUri ? '/speakup/graphql' : '/analytics/graphql',
        credentials: 'include',
    })

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = window.localStorage.getItem(accessTokenKey)

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        }
    })

    // Create a WebSocket link:
    subscriptionClient = createSubscriptionClient()
    const wsLink = new WebSocketLink(subscriptionClient)

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
        // split based on operation type
        ({ query }) => {
            const definition = getMainDefinition(query)
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            )
        },
        wsLink,
        authLink.concat(httpLink)
    )
    const client = new ApolloClient({
        cache,
        link: ApolloLink.from([errorLink, link]),
    })
    // A client can have defaultOptions key doc at below link
    // https://www.apollographql.com/docs/react/api/core/ApolloClient/#example-defaultoptions-object

    return client
}

export {
    createClient as default,
    createSubscriptionClient,
    closeSubscriptionClient,
}
