import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    useZsAddAllForHistory,
    useZsDailyData,
    useZsLiveData,
} from '../../../zustand-store'
import { getLiveUsageFor } from '../../service'
import {
    formatDataForChart,
    generateDayRanges,
    getRedisLiveDataFor,
    getRedisUsageDataForRange,
} from '../../utilities'
import DateFilter from './partials/DateFilter'
import HistoryCards from './partials/HistoryCards'
import HistoryExtendedLiveCharts from './partials/HistoryExtendedCharts'
import HistoryLiveChart from './partials/HistoryLiveChart'
import HistoryRangeChart from './partials/HistoryRangeChart'
import RangeDateFilter from './partials/RangeDateFilter'

export const History = () => {
    const { t } = useTranslation()
    const [filterDay, setFilterDay] = useState<Date>(
        moment().subtract(1, 'day').toDate()
    )

    const [startRangeDate, setStartRangeDate] = useState<Date>(
        moment().subtract(1, 'week').toDate()
    )
    const [endRangeDate, setEndRangeDate] = useState<Date>(
        moment().subtract(1, 'day').toDate()
    )

    const handleRangeChange = (dates: Date[]) => {
        const [startRangeDate, endRangeDate] = dates
        setStartRangeDate(startRangeDate)
        setEndRangeDate(endRangeDate)
    }

    const day = moment(filterDay).format('YYYY-MM-DD')

    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    const zsAddAllForHistory = useZsAddAllForHistory()

    const allLiveData = useZsLiveData()
    const allDailyData = useZsDailyData()

    const cachedLiveData = getRedisLiveDataFor(day, allLiveData)

    const startRangeDateStr = moment(startRangeDate).format('YYYY-MM-DD')
    const endRangeDateStr = moment(endRangeDate).format('YYYY-MM-DD')

    const getRedisUsageDataForRangeCB = useCallback(
        (sRange: string, eRange: string) =>
            getRedisUsageDataForRange(sRange, eRange, allDailyData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [startRangeDateStr, endRangeDateStr, allDailyData.length]
    )

    const ranges = useMemo(() => generateDayRanges(false), [])
    const chartData = formatDataForChart(cachedLiveData, day, ranges)

    const getLiveUsageForCB = useCallback(
        (_d: string) => {
            getLiveUsageFor({
                day: _d,
            }).then((data) => {
                if (data) {
                    zsAddAllForHistory(data, _d)
                }
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [day]
    )

    const dataForRange = getRedisUsageDataForRangeCB(
        startRangeDateStr,
        endRangeDateStr
    )

    const dataExtendedDaily = getRedisUsageDataForRangeCB(
        moment().subtract(1, 'day').format('YYYY-MM-DD'),
        moment().subtract(1, 'day').format('YYYY-MM-DD')
    )

    useEffect(() => {
        if (cachedLiveData.length === 0) {
            timeoutRef.current = setTimeout(() => {
                getLiveUsageForCB(day)
            }, 100)
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
                timeoutRef.current = null
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day])
    return (
        <div className="mt-8 space-y-4">
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg font-medium leading-6 text-gray-900 pb-2">
                    {t('Usage history / day')}
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3  ">
                    {/* filter */}
                    <DateFilter
                        handleDateSelection={setFilterDay}
                        selectedDay={filterDay}
                    />
                    <HistoryCards
                        dataType="LiveUsageRedis"
                        cardsData={cachedLiveData}
                    />
                </div>
            </div>

            {/* Activity table (small breakpoint and up) */}
            <div>
                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 ">
                    <div className="mt-2 flex flex-col">
                        <HistoryLiveChart chartData={chartData} />
                    </div>
                    <div className="mt-2 flex flex-col">
                        <HistoryExtendedLiveCharts
                            extendedData={dataExtendedDaily}
                            type={'yesterday'}
                        />
                    </div>
                </div>
            </div>

            {/** second part  */}
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 pt-4">
                <h2 className="text-lg font-medium leading-6 text-gray-900  pb-2">
                    {t('Usage history / date range')}
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <RangeDateFilter
                        onRangeChange={handleRangeChange}
                        startRangeDate={startRangeDate}
                        endRangeDate={endRangeDate}
                        minDate={
                            allDailyData.length > 0
                                ? moment(
                                      allDailyData[allDailyData.length - 1].ts
                                  ).toDate()
                                : moment().subtract(1, 'day').toDate()
                        }
                    />
                    <HistoryCards
                        dataType="DailyUsageRedis"
                        cardsData={dataForRange}
                    />
                </div>
            </div>

            <div>
                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                    <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                        {t('Daily')}
                    </h2>
                    <div className="mt-2 flex flex-col">
                        <HistoryRangeChart
                            startRangeDate={startRangeDate}
                            endRangeDate={endRangeDate}
                            data={dataForRange}
                            aggregationType="daily"
                        />
                    </div>

                    <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                        {t('Weekly')}
                    </h2>
                    <div className="mt-4 flex flex-col">
                        <HistoryRangeChart
                            startRangeDate={startRangeDate}
                            endRangeDate={endRangeDate}
                            data={dataForRange}
                            aggregationType="weekly"
                        />
                    </div>

                    <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                        {t('Monthly')}
                    </h2>
                    <div className="mt-4 flex flex-col">
                        <HistoryRangeChart
                            startRangeDate={startRangeDate}
                            endRangeDate={endRangeDate}
                            data={dataForRange}
                            aggregationType="monthly"
                        />
                    </div>

                    <div className="mt-2 flex flex-col">
                        <HistoryExtendedLiveCharts
                            extendedData={dataForRange}
                            type={'range'}
                        />
                    </div>
                </div>
            </div>

            {/** en second part */}
        </div>
    )
}
