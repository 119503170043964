import {
    CheckCircleIcon,
    EnvelopeIcon,
    InformationCircleIcon,
    XCircleIcon,
} from '@heroicons/react/20/solid'
import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'

import { useHistory } from 'react-router-dom'
import { AppLogger } from '../../AppLogger'
import SpeakupLogo from '../common/SpeakupLogo'

import { changePassword, validateResetToken } from './service'
import { useTranslation } from 'react-i18next'

enum ChangeStatus {
    ok,
    error,
    empty,
}
interface Props {
    resetToken: string
}

interface ChangePasswordPayload {
    eml: string
    dsp: string
    unm: string
}

const lowercaseRegex = /(?=.*[a-z])/
const uppercaseRegex = /(?=.*[A-Z])/
const numericRegex = /(?=.*[0-9])/
const specialRegex = /\W|_/g

const logger = AppLogger.getInstance()
const ChangePasswordForm: React.FC<Props> = ({ resetToken }) => {
    const { t } = useTranslation()

    const ChangePasswordSchema = Yup.object().shape({
        password: Yup.string()
            .matches(
                lowercaseRegex,
                t('At least one lowercase letter and one uppercase letter')
            )
            .matches(
                uppercaseRegex,
                t('At least one lowercase letter and one uppercase letter')
            )
            .matches(
                numericRegex,
                t('At least one numeric character and one special character')
            )
            .matches(
                specialRegex,
                t('At least one numeric character and one special character')
            )
            .required(t('required'))
            .min(8, t('At least 8 characters')),
        passwordConfirm: Yup.string()
            .required(t('required'))
            .oneOf([Yup.ref('password')], t('Password does not match')),
    })
    const [changePasswordPayload, setChangePasswordPayload] =
        useState<ChangePasswordPayload | null>(null)

    const [changePasswordStatus, setChangePasswordStatus] =
        useState<ChangeStatus>(ChangeStatus.empty)

    const history = useHistory()

    const renderChangeStatus = (status: ChangeStatus) => {
        switch (status) {
            case ChangeStatus.ok:
                return (
                    <div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <CheckCircleIcon
                                    className="h-5 w-5 text-green-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-green-800">
                                    {t('Change password completed')}
                                </h3>

                                <div className="mt-4">
                                    <div className="-mx-2 -my-1.5 flex">
                                        <button
                                            onClick={() => history.push('/')}
                                            type="button"
                                            className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                        >
                                            {t('Go to login page')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case ChangeStatus.error:
                return (
                    <div className="rounded-md bg-red-50 p-4 mb-5">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <XCircleIcon
                                    className="h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">
                                    {t(
                                        'Error while trying to change the password'
                                    )}
                                </h3>
                                <div className="mt-2 text-sm text-red-700">
                                    <ul className="list-disc space-y-1 pl-5">
                                        <li>{t('Please to try later')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            default:
                break
        }
    }

    useEffect(() => {
        function decodeResetToken() {
            try {
                const changePasswordPayload =
                    jwtDecode<ChangePasswordPayload>(resetToken)
                setChangePasswordPayload(changePasswordPayload)
            } catch (e) {
                logger.error(
                    `Error while decoding JWT resetToken:${resetToken}`
                )
                history.push(
                    {
                        pathname: 'error',
                    },
                    {
                        reason: t('Invalid change password request'),
                        description: t(
                            `Sorry, we couldn't validate the change password request`
                        ),
                    }
                )
            }
        }
        decodeResetToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetToken])

    useEffect(() => {
        async function isValidPCR(e: string) {
            return validateResetToken(e, resetToken)
        }
        if (changePasswordPayload?.eml) {
            isValidPCR(changePasswordPayload?.eml).then((isValid) => {
                if (isValid === false) {
                    history.push('/')
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changePasswordPayload?.eml, resetToken])

    logger.info(changePasswordPayload)

    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <SpeakupLogo
                        fillColor="rgb(8 145 178)"
                        text={t('Reset password')}
                    />
                    {renderChangeStatus(changePasswordStatus)}
                    <>
                        <div className="isolate -space-y-px rounded-md shadow-sm opacity-70">
                            <div className="relative rounded-md rounded-b-none border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:border-cyan-600 focus-within:ring-1 focus-within:ring-cyan-600">
                                <label
                                    htmlFor="name"
                                    className="block text-xs font-medium text-gray-900"
                                >
                                    {t('Name')}:
                                    {changePasswordPayload?.dsp || ''}
                                </label>
                            </div>
                            <div className="relative rounded-md rounded-t-none border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:border-cyan-600 focus-within:ring-1 focus-within:ring-cyan-600">
                                <label
                                    htmlFor="email"
                                    className="block text-xs font-medium text-gray-900"
                                >
                                    {t('Email')}:
                                    {changePasswordPayload?.eml || ''}
                                </label>
                            </div>

                            <div className="relative rounded-md rounded-t-none border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:border-cyan-600 focus-within:ring-1 focus-within:ring-cyan-600">
                                <label
                                    htmlFor="Username"
                                    className="block text-xs font-medium text-gray-900"
                                >
                                    {t('Username')}:{' '}
                                    {changePasswordPayload?.unm || ''}
                                </label>
                            </div>
                        </div>

                        <div className="relative">
                            <div
                                className="absolute inset-0 flex items-center"
                                aria-hidden="true"
                            >
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center">
                                <span className="bg-white px-3 text-lg font-medium text-gray-900">
                                    {t('New password')}
                                </span>
                            </div>
                        </div>
                        <div className="rounded-md bg-blue-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <InformationCircleIcon
                                        className="h-5 w-5 text-blue-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-blue-700">
                                        {t('New password restrictions')}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-2 text-sm text-blue-700">
                                <ul className="list-disc space-y-1 pl-5">
                                    <li>{t('At least 8 characters')}</li>
                                    <li>
                                        {t(
                                            'At least one numeric character and one special character'
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            'At least one lowercase letter and one uppercase letter'
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                    <Formik
                        initialValues={{
                            password: '',
                            passwordConfirm: '',
                        }}
                        validationSchema={ChangePasswordSchema}
                        onSubmit={async ({ password, passwordConfirm }) => {
                            const msg = await changePassword({
                                email: changePasswordPayload?.eml!,
                                resetToken,
                                password,
                                passwordConfirmation: passwordConfirm,
                            })
                            if (msg && msg === 'PASSWORD_RESET_DONE') {
                                setChangePasswordStatus(ChangeStatus.ok)
                            } else {
                                setChangePasswordStatus(ChangeStatus.error)
                            }
                        }}
                    >
                        {({ isSubmitting, errors, touched }) => (
                            <Form className="mt-4 space-y-6">
                                <div className="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-cyan-600 focus-within:ring-1 focus-within:ring-cyan-600">
                                    <label
                                        htmlFor="password"
                                        className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                                    >
                                        {t('Password')}
                                    </label>
                                    <Field
                                        id="password"
                                        name="password"
                                        type={'password'}
                                        className={
                                            errors.password && touched.password
                                                ? 'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm'
                                                : 'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm'
                                        }
                                    />

                                    {errors.password && touched.password && (
                                        <p
                                            className="mt-2 text-sm text-red-600"
                                            id="email-error"
                                        >
                                            {t(errors.password)}
                                        </p>
                                    )}
                                </div>

                                <div className="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-cyan-600 focus-within:ring-1 focus-within:ring-cyan-600">
                                    <label
                                        htmlFor="passwordConfirm"
                                        className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                                    >
                                        {t('Confirm password')}
                                    </label>
                                    <Field
                                        id="passwordConfirm"
                                        name="passwordConfirm"
                                        type={'password'}
                                        className={
                                            errors.passwordConfirm &&
                                            touched.passwordConfirm
                                                ? 'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm'
                                                : 'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm'
                                        }
                                    />

                                    {errors.passwordConfirm &&
                                        touched.passwordConfirm && (
                                            <p
                                                className="mt-2 text-sm text-red-600"
                                                id="email-error"
                                            >
                                                {t(errors.passwordConfirm)}
                                            </p>
                                        )}
                                </div>
                                <div>
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                    >
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                            <EnvelopeIcon
                                                className="h-5 w-5 text-cyan-500 group-hover:text-cyan-400"
                                                aria-hidden="true"
                                            />
                                        </span>
                                        {t('Reset')}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default ChangePasswordForm
