import { CalendarDaysIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import DatePicker from 'react-datepicker'

interface Props {
    onRangeChange: Function
    startRangeDate: Date
    endRangeDate: Date
    minDate: Date
}

const RangeDateFilter: React.FC<Props> = ({
    onRangeChange,
    startRangeDate,
    endRangeDate,
    minDate,
}) => {
    console.log(`minDate is:${moment(minDate).format('YYYY-MM-DD')}`)
    return (
        <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="p-5">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <CalendarDaysIcon
                            className="h-6 w-6 text-gray-400"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="ml-2 w-0 flex-1">
                        <DatePicker
                            weekLabel="w"
                            showWeekNumbers
                            dateFormat={'yyyy-MM-dd'}
                            className="h-8  w-full text-center "
                            selected={startRangeDate}
                            startDate={startRangeDate}
                            endDate={endRangeDate}
                            onChange={(dates) => onRangeChange(dates)} //only when value has changed
                            selectsRange
                            maxDate={moment().subtract(1, 'day').toDate()}
                            minDate={minDate}
                            monthsShown={2}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RangeDateFilter
