import moment from 'moment'
import { AppLogger } from '../../AppLogger'
import {
    DailyUsageRedis,
    LiveUsageChart,
    LiveUsageRedis,
    DailyUsageChart,
} from '../common/constant'

const logger = AppLogger.getInstance()

/**
 *
 * @param n
 * @returns
 */
const formatNb = (n: number): string => {
    if (n < 10) {
        return `0${n}`
    }
    return `${n}`
}

/**
 *
 * @param hourRange
 * @returns
 */
const formatHourRange = (hourRange: string): string => {
    let formatedHr = hourRange
    const parts = hourRange.split(':')

    // 0 -> 23
    const hour = parseInt(parts[0])

    if (hour < 10) {
        formatedHr = `${hour}:${parts[1]}`
    }

    return formatedHr
}

export const generateDayRanges = (
    isSameDay: boolean,
    currHour: string = moment().format('HH')
) => {
    const xAxisCheckPointsStart = [
        ['00:00'],
        ['00:00', '06:00'],
        ['06:00', '07:00'],
        ['07:00', '08:00'],
    ]

    const xAxisCheckPointsEnd = [
        ['20:00', '21:00'],
        ['21:00', '22:00'],
        ['22:00', '23:59'],
    ]
    const ranges: string[][] = []

    for (let hour = 8; hour < 20; hour++) {
        // step 5,10,20,30,60
        let step = 60
        const currHourInt = parseInt(currHour)
        if (isSameDay) {
            if (currHourInt === hour) {
                step = 10
            } else if (currHourInt === hour + 1) {
                step = 30
            }
        }

        const rangeBy = Array.from(Array(60 / step), (_, x) => x * step)

        if (rangeBy.length > 1) {
            rangeBy.forEach((el, index) => {
                const range: string[] = []
                const s = el
                const e = el + step
                range.push(`${formatNb(hour)}:${formatNb(s)}`)

                if (index === rangeBy.length - 1) {
                    range.push(`${formatNb(hour + 1)}:00`)
                } else {
                    range.push(`${formatNb(hour)}:${formatNb(e)}`)
                }
                ranges.push(range)
            })
        } else {
            // when step is 60  the rangeBy will be = [0] => output [hour, hour+1]
            const range: string[] = []
            range.push(`${formatNb(hour)}:00`)
            range.push(`${formatNb(hour + 1)}:00`)
            ranges.push(range)
        }
    }
    return [...xAxisCheckPointsStart, ...ranges, ...xAxisCheckPointsEnd]
}

/**
 * formatDataForChart
 * @param res
 * @returns
 */

export const formatDataForChart = (
    res: LiveUsageRedis[],
    day: string,
    dayRanges: string[][]
): LiveUsageChart[] => {
    let fRes: LiveUsageChart[] = []
    if (res && res.length > 0) {
        const chunks = getLiveChunks(res.reverse(), day, dayRanges)

        chunks.forEach((chunk) => {
            const max = Math.max(
                ...chunk.map(function (el: LiveUsageRedis) {
                    return el.au
                })
            )
            // const average = chunk.reduce((a, b) => a + b.au, 0) / chunk.length
            fRes.push({
                cr: chunk[chunk.length - 1].cr,
                au: max,
                ts: chunk[chunk.length - 1].ts,
            })
        })

        logger.debug(
            `formatDataForChart xAxis :${JSON.stringify(
                fRes.map((f) => f.ts),
                null,
                3
            )}`
        )
    }

    return fRes
}

const getLiveChunks = (
    arr: LiveUsageRedis[],
    day: string,
    dayRanges: string[][]
) => {
    const isSameDay = moment().format('YYYY-MM-DD') === day
    const _chunks: LiveUsageRedis[][] = []

    // generateDayRanges(isSameDay, moment().format('HH'))
    let activeRanges = dayRanges

    if (isSameDay) {
        const startStream = arr[0].ts
        const endStream = arr[arr.length - 1].ts

        // filter
        activeRanges = activeRanges.filter((ck) => {
            const p = ck.length > 1 ? ck[1] : ck[0]
            const d1 = moment(`${day} ${p}`, 'YYYY-MM-DD HH:mm')
            if (
                d1.diff(moment(startStream), 'minute') >= 0 &&
                d1.diff(moment(endStream), 'minute') < 0
            ) {
                return true
            }

            return false
        })
        // add the
        activeRanges = [...activeRanges, [moment(endStream).format('HH:mm')]]

        // when the stream starts after midnight add the midnight rang
        if (moment(startStream).diff(moment().startOf('days'), 'minute') > 0) {
            activeRanges = [['00:00'], ...activeRanges]
        }
    }

    // each el = [a, b] =>  a< date <= b
    // if el =[a] => a
    activeRanges.forEach((el) => {
        if (el.length === 1) {
            const _data1 = arr.filter(
                (t) => moment(t.ts).format('HH:mm') === el[0]
            )

            if (_data1 && _data1.length > 0) {
                _chunks.push(
                    _data1.map((d) => ({
                        ...d,
                        ts: formatHourRange(el[0]),
                    }))
                )
            } else {
                _chunks.push([getEmptyLiveChunk(el[0])])
            }
        } else {
            const d1 = moment(`${day} ${el[0]}`, 'YYYY-MM-DD HH:mm')
            const d2 = moment(`${day} ${el[1]}`, 'YYYY-MM-DD HH:mm')

            const _data2 = arr.filter((t) =>
                moment(t.ts).isBetween(d1, d2, 'second', '(]')
            )

            if (_data2 && _data2.length > 0) {
                _chunks.push(
                    _data2.map((d) => ({
                        ...d,
                        ts: formatHourRange(el[1]),
                    }))
                )
            } else {
                _chunks.push([getEmptyLiveChunk(el[1])])
            }
        }
    })

    // logger.debug(JSON.stringify(_chunks, null, 3))
    return _chunks
}

/**
 *
 * @param day
 * @param hour
 */
const getEmptyLiveChunk = (hour: string): LiveUsageRedis => {
    return {
        id: '',
        au: 0,
        ts: formatHourRange(hour),
        cr: 0,
        us: 0,

        xOr: {},
        xTh: {},
        xOs: {},
    }
}

export const getInitials = (displayName?: string): string => {
    let dp = ''
    if (displayName) {
        const names = displayName.split(' ')
        dp =
            names.length > 1
                ? `${names[0].charAt(0)}${names[1].charAt(0)}`
                : names[0].charAt(0)
    }

    return dp
}

/**
 * getLiveDataFor
 * day format YYYY-MM-DD
 * @param day
 * @param data
 */
export const getRedisLiveDataFor = (day: string, data: LiveUsageRedis[]) => {
    logger.debug(`getRedisLiveDataFor day:${day} `)
    let res: LiveUsageRedis[] = []
    if (data && data.length > 0) {
        logger.debug(
            `getRedisLiveDataFor day:${day} data length:${data.length}
            )} `
        )

        res = data.filter((l) => {
            const date0 = moment(l.ts).format('YYYY-MM-DD')

            if (date0 === day) {
                return true
            }
            return false
        })

        logger.debug(
            `getRedisLiveDataFor day:${day} res:${res.length}
            )} `
        )
    }
    return res
}

/**
 *
 * @param sRange
 * @param eRange
 * @param data
 * @returns
 */
export const getRedisUsageDataForRange = (
    sRange: string,
    eRange: string,
    data: DailyUsageRedis[]
) => {
    logger.debug(`getRedisUsageDataForRange range:[${sRange} - ${eRange}] `)
    let res: DailyUsageRedis[] = []
    if (data && data.length > 0) {
        const d1 = moment(sRange, 'YYYY-MM-DD')
        const d2 = moment(eRange, 'YYYY-MM-DD')

        res = data.filter((d) => moment(d.ts).isBetween(d1, d2, 'day', '[]'))
    }
    return res
}

export const generateRanges = (
    startRangeDate: Date,
    endRangeDate: Date,
    aggregationType: AggregationType
) => {
    const ranges: string[][] = []

    switch (aggregationType) {
        case 'daily':
            let currDay = startRangeDate
            let dailyCondition = moment(currDay).isBetween(
                startRangeDate,
                endRangeDate,
                'day',
                '[]'
            )
            while (dailyCondition) {
                ranges.push([moment(currDay).format('YYYY-MM-DD')])
                currDay = moment(currDay).add(1, 'day').toDate()
                dailyCondition = moment(currDay).isBetween(
                    startRangeDate,
                    endRangeDate,
                    'day',
                    '[]'
                )
            }

            break

        case 'weekly':
            let currWeek = startRangeDate
            let weeklyCondition = moment(currWeek).isBetween(
                startRangeDate,
                endRangeDate,
                'week',
                '[]'
            )
            while (weeklyCondition) {
                const sw =
                    moment(currWeek).startOf('week').diff(startRangeDate) < 0
                        ? startRangeDate
                        : moment(currWeek).startOf('week')
                const ew =
                    moment(currWeek).endOf('week').diff(endRangeDate) < 0
                        ? moment(currWeek).endOf('week')
                        : endRangeDate
                ranges.push([
                    moment(sw).format('YYYY-MM-DD'),
                    moment(ew).format('YYYY-MM-DD'),
                ])
                currWeek = moment(currWeek).add(1, 'week').toDate()
                weeklyCondition = moment(currWeek).isBetween(
                    startRangeDate,
                    endRangeDate,
                    'week',
                    '[]'
                )
            }

            break

        case 'monthly':
            let currMonth = startRangeDate
            let monthlyCondition = moment(currMonth).isBetween(
                startRangeDate,
                endRangeDate,
                'month',
                '[]'
            )
            while (monthlyCondition) {
                const sm =
                    moment(currMonth).startOf('month').diff(startRangeDate) < 0
                        ? startRangeDate
                        : moment(currMonth).startOf('month')
                const em =
                    moment(currMonth).endOf('month').diff(endRangeDate) < 0
                        ? moment(currMonth).endOf('month')
                        : endRangeDate
                ranges.push([
                    moment(sm).format('YYYY-MM-DD'),
                    moment(em).format('YYYY-MM-DD'),
                ])
                currMonth = moment(currMonth).add(1, 'month').toDate()
                monthlyCondition = moment(currMonth).isBetween(
                    startRangeDate,
                    endRangeDate,
                    'month',
                    '[]'
                )
            }

            break

        default:
            break
    }
    return ranges
}

export const formatDataForDailyChart = (
    res: DailyUsageRedis[],
    aggregationType: AggregationType,
    ranges: string[][]
): DailyUsageChart[] => {
    let fRes: DailyUsageChart[] = []
    if (res && res.length > 0) {
        const chunks = getDailyChunks(res.reverse(), ranges, aggregationType)

        chunks.forEach((chunk) => {
            fRes.push({
                cr: sumDailyCr(chunk),
                avu: avgDailyAU(chunk),
                mxu: mxDailyAU(chunk),
                ts: chunk[chunk.length - 1].ts,
                sus: sumDailyUs(chunk),
            })
        })
    }

    return fRes
}

const getDailyChunks = (
    arr: DailyUsageRedis[],
    ranges: string[][],
    aggregationType: AggregationType
) => {
    const _chunks: DailyUsageRedis[][] = []

    let activeRanges = ranges

    activeRanges.forEach((el) => {
        if (el.length === 1) {
            const _data1 = arr.filter(
                (t) => moment(t.ts).format('YYYY-MM-DD') === el[0]
            )

            if (_data1 && _data1.length > 0) {
                _chunks.push(
                    _data1.map((d) => ({
                        ...d,
                        ts: formatRange(el[0], aggregationType),
                    }))
                )
            } else {
                _chunks.push([getEmptyDailyChunk(el[0], aggregationType)])
            }
        } else {
            const d1 = moment(el[0], 'YYYY-MM-DD')
            const d2 = moment(el[1], 'YYYY-MM-DD')

            const _data2 = arr.filter((t) =>
                moment(t.ts).isBetween(
                    d1,
                    d2,
                    aggregationType === 'weekly' ? 'week' : 'month',
                    '[]'
                )
            )

            if (_data2 && _data2.length > 0) {
                _chunks.push(
                    _data2.map((d) => ({
                        ...d,
                        ts: formatRange(el[1], aggregationType),
                    }))
                )
            } else {
                _chunks.push([getEmptyDailyChunk(el[1], aggregationType)])
            }
        }
    })

    // logger.debug(JSON.stringify(_chunks, null, 3))
    return _chunks
}

/**
 *
 * @param day
 * @param hour
 */
const getEmptyDailyChunk = (
    date: string,
    aggregationType: AggregationType
): DailyUsageRedis => {
    return {
        id: '',
        ts: formatRange(date, aggregationType),
        cr: 0,
        us: 0,
        av: 0,
        mx: 0,

        xOr: {},
        xTh: {},
        xOs: {},
    }
}

const formatRange = (date: string, aggregationType: AggregationType) => {
    let format = ''
    switch (aggregationType) {
        case 'daily':
            format = moment(date).format('MM-DD')
            break

        case 'weekly':
            format = `W-${moment(date).format('W')}`
            break

        case 'monthly':
            format = moment(date).format('MMM')
            break

        default:
            break
    }
    return format
}

export const sumDailyUs = (data: DailyUsageRedis[]) => {
    const r = data.reduce<number>((acc: number, d: DailyUsageRedis) => {
        return acc + d.us
    }, 0)
    return r
}

export const sumDailyCr = (data: DailyUsageRedis[]) => {
    const r = data.reduce<number>((acc: number, d: DailyUsageRedis) => {
        return acc + d.cr
    }, 0)
    return r
}

export const avgDailyAU = (data: DailyUsageRedis[]) => {
    let average = 0
    if (data.length) {
        average = data.reduce((a, b) => a + b.av, 0) / data.length
    }
    return average
}

export const mxDailyAU = (data: DailyUsageRedis[]) => {
    let mx = 0
    if (data.length) {
        mx = Math.max(
            ...data.map(function (el: DailyUsageRedis) {
                return el.mx
            })
        )
    }
    return mx
}

export type AggregationType = 'daily' | 'weekly' | 'monthly'

export type ExtendedAggregationType = 'yesterday' | 'range'
