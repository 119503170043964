import SpeakupLogo from './common/SpeakupLogo'
import { useHistory } from 'react-router-dom'
import { BASE_URL } from './common/constant'
import { useEffect, useState } from 'react'
import * as QueryString from 'query-string'
import { useTranslation } from 'react-i18next'

interface Props {}
interface UnsubscribeResp {
    ok: boolean
    msg: string
    code: string
}

const UnsubscribePage: React.FC<Props> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [response, setResponse] = useState<UnsubscribeResp | null>(null)
    useEffect(() => {
        async function unsubscribe(
            unsubscribeKey: string
        ): Promise<UnsubscribeResp | null> {
            try {
                const response = await fetch(`${BASE_URL}/login/unsubscribe`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        unsubscribeKey,
                    }),
                })
                const unsubscribeRes = await response.json()
                return unsubscribeRes as UnsubscribeResp
            } catch (error) {
                return null
            }
        }

        const { unsubscribeKey } = QueryString.parse(window.location.search)

        if (unsubscribeKey) {
            const key = unsubscribeKey as string
            unsubscribe(key)
                .then((res) => {
                    if (res && res.ok) {
                        setResponse({
                            ok: true,
                            msg: ` ${t(
                                'You request to unsubscribe has been performed successfully, therefore from now on you will not receive any email'
                            )}.
                             ${t(
                                 'For your information, you can still enable the email notification in your profile'
                             )}.`,
                            code: 'UNSUBSCRIBE_KEY_SUCCESS',
                        })
                    } else {
                        setResponse({
                            ok: false,
                            msg: t('Unsubscribe Key is not valid or expired'),
                            code: 'UNSUBSCRIBE_KEY_ERROR',
                        })
                    }
                })
                .catch((e) => {
                    console.log('unsubscribe unauthorized error  ', e)
                    setResponse({
                        ok: false,
                        msg: t('Unsubscribe Key is not valid or expired'),
                        code: 'UNSUBSCRIBE_KEY_ERROR',
                    })
                })
        } else {
            setResponse({
                ok: false,
                msg: t(
                    'Unsubscribe Key is required, in order to perform this operation'
                ),
                code: 'UNSUBSCRIBE_KEY_EMPTY',
            })
        }

        return () => {}
    }, [])

    return (
        <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
            <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
                <div className="flex flex-shrink-0 justify-center">
                    <SpeakupLogo fillColor="rgb(8 145 178)" />
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-base font-semibold text-cyan-600">
                            {response?.ok ? t('Success') : t('Error')}
                        </p>

                        <p className="mt-2 text-base text-red-500">
                            {response?.msg}
                        </p>
                        <div className="mt-6">
                            <button
                                className="text-base font-medium text-cyan-600 hover:text-cyan-500"
                                onClick={() => history.push('/')}
                            >
                                {t('Back to home page')}
                                <span aria-hidden="true"> &rarr;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default UnsubscribePage
