import { useHistory } from 'react-router-dom'

interface Props {
    fillColor: string
    text?: string
}

const SpeakupLogo: React.FC<Props> = ({ fillColor, text }) => {
    const history = useHistory()
    return (
        <div className="flex">
            <div
                className={`" w-10 h-10  cursor-pointer`}
                style={{ fill: fillColor }}
                onClick={() => history.push('/')}
            >
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 530 1000"
                >
                    <path d="M221.60329,1000V589.11313c-38.60519-15.47873-86.04686-54.12973-86.04686-54.12973L9.269,408.51732A26.35884,26.35884,0,0,1,9.001,371.165h0a26.275,26.275,0,0,1,18.58684-7.87449c15.94872.7169,28.83193,11.13836,38.58458,20.91391C83.55222,401.58419,125.5358,450.904,156.82765,450.904s64.77564-63.88238,64.77564-117.33872V76.02479a26.40522,26.40522,0,0,1,52.81044,0V303.05448H296.624L307.62954,25.33987a26.40349,26.40349,0,0,1,52.7651,2.10262l-10.93727,275.612h23.90979L399.44829,52.89832a26.41157,26.41157,0,0,1,52.54064,5.45581L426.46849,303.05448H444.4076l.62482,1.41091,29.99547-166.796a26.39645,26.39645,0,1,1,51.9584,9.34953L483.43835,389.21587c-12.07973,67.10043-28.4082,139.5055-63.68082,178.06717L443.53921,1000Z" />
                </svg>
            </div>
            <div className="pt-5">
                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                    {text ? text : ' SpeakUp Analytics'}
                </h2>
            </div>
        </div>
    )
}

export default SpeakupLogo
