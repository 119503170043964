import SpeakupLogo from './common/SpeakupLogo'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
interface Props {
    reason?: string
    description?: string
}

const ErrorPage: React.FC<Props> = ({ reason, description }) => {
    const { t } = useTranslation()
    const history = useHistory()
    return (
        <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
            <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
                <div className="flex flex-shrink-0 justify-center">
                    <SpeakupLogo fillColor="rgb(8 145 178)" />
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-base font-semibold text-cyan-600">
                            {t('Erorr')}
                        </p>

                        <p className="mt-2 text-base text-red-500">
                            {description
                                ? description
                                : t('Unexpected error, please try again later')}
                        </p>
                        <div className="mt-6">
                            <button
                                className="text-base font-medium text-cyan-600 hover:text-cyan-500"
                                onClick={() => history.push('/')}
                            >
                                {t('Back to home page')}
                                <span aria-hidden="true"> &rarr;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default ErrorPage
