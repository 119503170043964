import { useEffect, useRef, useState } from 'react'
import { Switch } from '@headlessui/react'

import { Formik, Form, Field, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import {
    useZsActiveMenuItem,
    useZsVersionDelete,
    useZsVersionUpdate,
    useZsVersionAdd,
    useZsUpdateMenuItem,
    useZsUser,
} from '../../../../zustand-store'
import { VersionData_versionControl } from '../../../../../generated/VersionData'

import { MenuItems } from '../../../../common/constant'
import { XCircleIcon } from '@heroicons/react/24/outline'
import DestructiveDialog from '../../../../common/DestructiveDialog'
import ConfirmDialog from '../../../../common/ConfirmDialog'
import { VersionFormValues, getNextVersionNbr } from '../../../service'
import { useVersions } from '../useVersions'
import { VersionControlCreateInput } from '../../../../../generated/globalTypes'

export const VersionForm = () => {
    const { t } = useTranslation()
    const user = useZsUser()
    const formikRef = useRef<any>(null)
    const submitBtn = useRef<HTMLButtonElement>(null)

    const [openConfirmDel, setOpenConfirmDel] = useState(false)
    const [openConfirmSave, setOpenConfirmSave] = useState(false)

    const zsActiveMenu = useZsActiveMenuItem()
    const changeMenu = useZsUpdateMenuItem()

    const zsAddVersion = useZsVersionAdd()
    const zsUpdateVersion = useZsVersionUpdate()
    const zsDeleteVersion = useZsVersionDelete()

    const { deleteVersion, createVersion, updateVersion } = useVersions(
        user?.userId!
    )

    const handleChangeMenu = (menu: MenuItems) => {
        changeMenu(menu)
    }
    let VersionSchema = Yup.object().shape({
        id: Yup.string(),
        platform: Yup.string().required('Platform is required!'),
        versionNbr: Yup.number().required('Required'),
        isUpdateRequired: Yup.boolean(),
        description: Yup.string().required(t('Required')),
    })

    const [editMode, setEditMode] = useState(!!zsActiveMenu.menuData)
    const [createError, setCreateError] = useState(false)

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    const extractFormValues = (_versionData?: any) => {
        const versionData = (
            _versionData ? _versionData : zsActiveMenu.menuData
        ) as VersionData_versionControl

        return versionData
            ? ({
                  id: versionData.id,
                  target: 'SpeakUp',
                  platform: versionData.platform,
                  versionNbr: versionData.versionNbr,
                  isUpdateRequired: versionData.isUpdateRequired,
                  description: versionData.description,
              } as VersionFormValues)
            : ({
                  id: undefined,
                  target: 'SpeakUp',
                  platform: undefined,
                  versionNbr: undefined,
                  isUpdateRequired: false,
                  description: '',
              } as VersionFormValues)
    }

    const handleSaveB4Leave = () => {
        // inform user,  if he wants to save the changes
        if (submitBtn.current) {
            submitBtn.current.click()
            handleChangeMenu(MenuItems.versions)
        }
    }

    const handleDelete = async (id?: string) => {
        if (id) {
            // ask user to confirm the delete
            zsDeleteVersion(id)
            await deleteVersion(id)
            setOpenConfirmDel(false)
            handleChangeMenu(MenuItems.versions)
        }
    }

    useEffect(() => {
        if (zsActiveMenu.menuData?.id) {
            const newFormValues = extractFormValues(zsActiveMenu.menuData!)

            if (formikRef.current) {
                formikRef.current.setValues(newFormValues)
                //to reset dirty status
                formikRef.current.resetForm({ values: newFormValues })
                setEditMode(true)
            }
        }
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zsActiveMenu.menuData?.id])

    return (
        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <Formik
                innerRef={formikRef}
                initialValues={extractFormValues()}
                validationSchema={VersionSchema}
                onSubmit={async (
                    values: VersionFormValues,
                    actions: FormikHelpers<VersionFormValues>
                ) => {
                    try {
                        setCreateError(false)
                        let _updatedUserForm: VersionFormValues
                        if (editMode) {
                            const updated = await updateVersion(
                                {
                                    description: {
                                        set: values.description,
                                    },
                                },
                                values.id!
                            )
                            if (updated.data && updated.data?.version) {
                                zsUpdateVersion(
                                    zsActiveMenu.menuData!.id,
                                    updated.data.version
                                )

                                _updatedUserForm = extractFormValues(
                                    updated.data.version
                                )
                                // Update form values
                                actions.setValues(_updatedUserForm)
                                //to reset dirty status
                                actions.resetForm({ values: _updatedUserForm })
                            }
                        } else {
                            const created = await createVersion(
                                values as VersionControlCreateInput
                            )
                            if (
                                created &&
                                created.data &&
                                created.data.version
                            ) {
                                zsAddVersion(created.data.version)
                                console.log(created.data.version)
                                _updatedUserForm = extractFormValues(
                                    created.data.version
                                )

                                setEditMode(true)
                                // Update form values
                                actions.setValues(_updatedUserForm)
                                //to reset dirty status
                                actions.resetForm({ values: _updatedUserForm })
                            }
                        }
                    } catch (error) {
                        setCreateError(true)
                    }
                }}
            >
                {({
                    values,
                    errors,
                    isSubmitting,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    setValues,
                    isValid,
                    dirty,
                }) => (
                    <Form className="space-y-8 divide-y divide-gray-200">
                        {createError && (
                            <div className="rounded-md bg-red-50 p-4 mt-8">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <XCircleIcon
                                            className="h-5 w-5 text-red-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">
                                            {t(
                                                'There is an error with your submission, please try again later'
                                            )}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="space-y-6 sm:space-y-5">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                                        {t('Version')}
                                    </h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        {t(
                                            'This information will not be displayed publicly'
                                        )}
                                    </p>
                                </div>

                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="platform"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t('Platform')}
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <select
                                                disabled={editMode}
                                                id="platform"
                                                name="platform"
                                                autoComplete="platform"
                                                value={values.platform}
                                                onChange={async (v) => {
                                                    const _platform =
                                                        v.target.value
                                                    if (_platform) {
                                                        setFieldValue(
                                                            'platform',
                                                            _platform
                                                        )
                                                        const _nextVersion =
                                                            await getNextVersionNbr(
                                                                _platform
                                                            )

                                                        setFieldValue(
                                                            'versionNbr',
                                                            _nextVersion
                                                        )
                                                    } else {
                                                        setFieldValue(
                                                            'versionNbr',
                                                            undefined
                                                        )
                                                    }
                                                }}
                                                className={`${
                                                    errors.platform &&
                                                    touched.platform
                                                        ? 'block w-full max-w-lg rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:max-w-xs sm:text-sm'
                                                        : 'block w-full rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:border-cyan-500 focus:ring-cyan-500  sm:max-w-xs sm:text-sm sm:leading-6'
                                                } `}
                                            >
                                                <option
                                                    value=""
                                                    label="Select a platform"
                                                >
                                                    Select a platform{' '}
                                                </option>
                                                <option value="ios">ios</option>
                                                <option value="android">
                                                    android
                                                </option>
                                            </select>

                                            {errors.platform &&
                                                touched.platform && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="platform"
                                                    >
                                                        {t(errors.platform)}
                                                    </p>
                                                )}
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="versionNumber"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t('Version Nbr.')}
                                        </label>

                                        <div className="mt-1 sm:col-span-2 sm:mt-0  ">
                                            <Field
                                                id="versionNumber"
                                                disabled={true}
                                                name="versionNbr"
                                                type="number"
                                                className={`${
                                                    errors.versionNbr &&
                                                    touched.versionNbr
                                                        ? 'block w-full max-w-lg rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:max-w-xs sm:text-sm'
                                                        : 'block w-full max-w-lg rounded-md border-gray-300 shadow-sm  focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm'
                                                } `}
                                            />
                                            {errors.versionNbr &&
                                                touched.versionNbr && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="version-number"
                                                    >
                                                        {t(errors.versionNbr)}
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="updateType"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t('Update type')}
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <Switch.Group
                                                as="div"
                                                className="flex items-center"
                                            >
                                                <Switch
                                                    id="updateType"
                                                    disabled={editMode}
                                                    checked={
                                                        values.isUpdateRequired
                                                    }
                                                    onChange={() =>
                                                        setFieldValue(
                                                            'isUpdateRequired',
                                                            !values.isUpdateRequired
                                                        )
                                                    }
                                                    className={classNames(
                                                        values.isUpdateRequired
                                                            ? 'bg-cyan-600'
                                                            : 'bg-gray-200',
                                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
                                                    )}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            values.isUpdateRequired
                                                                ? 'translate-x-5'
                                                                : 'translate-x-0',
                                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                        )}
                                                    />
                                                </Switch>
                                                <Switch.Label
                                                    as="span"
                                                    className="ml-3"
                                                >
                                                    <span className="text-sm font-medium text-gray-900">
                                                        {t('Requires Update')}
                                                    </span>
                                                </Switch.Label>
                                            </Switch.Group>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="description"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t('Description')}
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <>
                                                <Field
                                                    id="description"
                                                    name="description"
                                                    type="text"
                                                    className={`${
                                                        errors.description &&
                                                        touched.description
                                                            ? 'block w-full max-w-lg rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:max-w-xs sm:text-sm'
                                                            : 'block w-full max-w-lg rounded-md border-gray-300 shadow-sm  focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm'
                                                    } `}
                                                />
                                                {errors.description &&
                                                    touched.description && (
                                                        <p
                                                            className="mt-2 text-sm text-red-600"
                                                            id="description"
                                                        >
                                                            {t(
                                                                errors.description
                                                            )}
                                                        </p>
                                                    )}
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <button
                                    onClick={() =>
                                        dirty
                                            ? setOpenConfirmSave(true)
                                            : handleChangeMenu(
                                                  MenuItems.versions
                                              )
                                    }
                                    type="button"
                                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                >
                                    {t('Cancel')}
                                </button>

                                {editMode && (
                                    <button
                                        onClick={() => setOpenConfirmDel(true)}
                                        type="button"
                                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    >
                                        {t('Delete')}
                                    </button>
                                )}

                                <button
                                    ref={submitBtn}
                                    disabled={!isValid}
                                    type="submit"
                                    className={`${
                                        isValid ? 'opacity-100' : 'opacity-50'
                                    } ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm bg-cyan-800 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                                >
                                    {t('Save')}
                                </button>
                            </div>
                        </div>
                        {openConfirmSave && (
                            <ConfirmDialog
                                title="Save your changes?"
                                description="Are you sure you want to leave this page and discard your changes?"
                                btnTitle="Save changes"
                                confirmCallbackFn={() =>
                                    isValid
                                        ? handleSaveB4Leave()
                                        : setOpenConfirmSave(false)
                                }
                                cancelCallbackFn={() => {
                                    setOpenConfirmSave(false)
                                    handleChangeMenu(MenuItems.versions)
                                }}
                            />
                        )}

                        {openConfirmDel && (
                            <DestructiveDialog
                                title="Delete version"
                                description="Are you sure you want to delete this version?"
                                btnTitle="Delete"
                                confirmCallbackFn={() =>
                                    handleDelete(values.id)
                                }
                                cancelCallbackFn={() => {
                                    setOpenConfirmDel(false)
                                }}
                            />
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}
