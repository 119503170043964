import moment from 'moment'
import create from 'zustand'
import { AppLogger } from '../AppLogger'
import { LoginData_login } from '../generated/LoginData'
import { LoginDetail } from '../generated/LoginDetail'

import {
    DailyUsageRedis,
    InstanceUsages,
    LiveUsageRedis,
    MenuItems,
    TOKEN_KEY,
    User,
    USER_KEY,
} from './common/constant'
import { getDailyUsage, getLiveUsageFor } from './main/service'
import { VersionData_versionControl } from '../generated/VersionData'
import { CreateOneVersionControl_version } from '../generated/CreateOneVersionControl'
import { UpdateOneVersionControl_version } from '../generated/UpdateOneVersionControl'
import { VersionsData_versionControls } from '../generated/VersionsData'

const logger = AppLogger.getInstance()

type State = {
    user: User | null
    userActions: {
        login: (user: User) => void
        logout: () => void
    }
    liveData: LiveUsageRedis[]
    liveExtendedData: LiveUsageRedis[]
    liveActions: {
        addNew: (liveItem: LiveUsageRedis) => void
        addAll: (liveItems: LiveUsageRedis[]) => void
        addAllForHistory: (historyItems: LiveUsageRedis[], day: string) => void
        updateLast: (liveItem: LiveUsageRedis) => void
    }
    dailyData: DailyUsageRedis[]
    dailyActions: {
        addNew: (dailyItem: DailyUsageRedis) => void
        addAll: (dailyItems: DailyUsageRedis[]) => void
    }

    activeMenuItem: {
        menuItem: MenuItems
        menuData?: LoginData_login | VersionData_versionControl
    }

    activeMenuItemActions: {
        updateMenu: (
            menu: MenuItems,
            menuData?: LoginData_login | VersionData_versionControl
        ) => void
    }

    instanceUsages: InstanceUsages
    instanceUsagesActions: {
        addOrUpdate: (data: InstanceUsages) => void
    }

    logins: LoginDetail[]

    loginsActions: {
        addAll: (logins: LoginDetail[]) => void
        add: (login: LoginDetail) => void
        update: (loginId: string, login: LoginDetail) => void
        delete: (loginId: string) => void
    }

    versions: VersionsData_versionControls[]

    versionsActions: {
        addAll: (versions: VersionsData_versionControls[]) => void
        add: (version: CreateOneVersionControl_version) => void
        update: (
            versionId: string,
            version: UpdateOneVersionControl_version
        ) => void
        delete: (versionId: string) => void
    }
}

const useStore = create<State>((set, get) => ({
    user: null,
    userActions: {
        login: async (user: User) => {
            const state = get()

            logger.debug(
                `userActions state state.liveData.length :${state.liveData.length} `
            )

            // get the data
            const currDay = moment().format('YYYY-MM-DD')
            const data = await getLiveUsageFor({
                day: currDay,
            })
            if (data) {
                state.liveActions.addAll(data)
            }
            const dailyData = await getDailyUsage()
            if (dailyData) {
                state.dailyActions.addAll(dailyData)
            }

            set((_state) => ({ user }))
        },
        logout: () =>
            set((_state) => {
                localStorage.removeItem(TOKEN_KEY)
                localStorage.removeItem(USER_KEY)
                return {
                    user: null,
                    activeMenuItem: { menuItem: MenuItems.home },
                }
            }),
    },
    liveData: [],
    liveExtendedData: [],
    liveActions: {
        addNew: (liveItem: LiveUsageRedis) =>
            set((_state) => {
                const found = _state.liveData.find((l) => l.id === liveItem.id)
                if (!found) {
                    const _liveData = [liveItem, ..._state.liveData]
                    return { liveData: _liveData }
                } else {
                    return _state
                }
            }),

        addAll: (liveItems: LiveUsageRedis[]) =>
            set((_state) => {
                const _liveData = [...liveItems]
                return { liveData: _liveData }
            }),

        addAllForHistory: (historyItems: LiveUsageRedis[], day: string) =>
            set((_state) => {
                const isAdded = _state.liveData.find(
                    (l) => moment(l.ts).format('YYYY-MM-DD') === day
                )
                if (!isAdded) {
                    const _historyLiveData = [
                        ..._state.liveData,
                        ...historyItems,
                    ]
                    return { liveData: _historyLiveData }
                }
                return _state
            }),

        updateLast: (liveItem: LiveUsageRedis) =>
            set((_state) => {
                const targetRec = _state.liveData.find(
                    (l) =>
                        moment(l.ts).format('YYYY-MM-DD HH:mm') ===
                        moment(liveItem.ts).format('YYYY-MM-DD HH:mm')
                )

                if (targetRec) {
                    if (
                        targetRec.au !== liveItem.au ||
                        targetRec.cr !== liveItem.cr
                    ) {
                        //minute found and values are != -> update the record
                        const filtered = _state.liveData.filter(
                            (l) => l.id !== liveItem.id
                        )
                        const _liveData = [liveItem, ...filtered]
                        return { liveData: _liveData }
                    } else {
                        // minute found but same values -> do nothing
                        return _state
                    }
                } else {
                    // not the same minute -> create an entry for this minute
                    const _liveData = [liveItem, ..._state.liveData]
                    return { liveData: _liveData }
                }
            }),
    },

    dailyData: [],
    dailyActions: {
        addNew: (dailyItem: DailyUsageRedis) =>
            set((_state) => {
                const _dailyData = [dailyItem, ..._state.dailyData]
                return { dailyData: _dailyData }
            }),

        addAll: (dailyItems: DailyUsageRedis[]) =>
            set((_state) => {
                const _dailyData = [...dailyItems]
                return { dailyData: _dailyData }
            }),
    },
    activeMenuItem: { menuItem: MenuItems.home },
    activeMenuItemActions: {
        updateMenu: (menu, data) =>
            set((_state) => {
                if (data)
                    return {
                        activeMenuItem: { menuItem: menu, menuData: data },
                    }
                else return { activeMenuItem: { menuItem: menu } }
            }),
    },

    instanceUsages: {
        createdAt: '',
        instances: [],
    },
    instanceUsagesActions: {
        addOrUpdate: (data) =>
            set((_state) => {
                if (
                    JSON.stringify(data.instances) !==
                    JSON.stringify(_state.instanceUsages.instances)
                ) {
                    return { instanceUsages: data }
                }
                return _state
            }),
    },

    logins: [],
    loginsActions: {
        addAll: (logins) =>
            set((_state) => {
                return { logins }
            }),
        add: (login) =>
            set((_state) => {
                let logins = _state.logins.filter((l) => l.id !== login.id)
                logins = [login, ..._state.logins]
                return { logins }
            }),
        update: (loginId, login) =>
            set((_state) => {
                const logins = _state.logins.filter((l) => l.id !== loginId)

                if (_state.user && _state.user.userId === loginId) {
                    const _user = {
                        ..._state.user,
                        imgData: login.imgData,
                        displayName: login.displayName,
                        isNotify: login.isNotify,
                        isAdmin: login.isAdmin,
                    }
                    localStorage.removeItem(USER_KEY)
                    localStorage.setItem(USER_KEY, JSON.stringify(_user))
                    return { logins: [login, ...logins], user: _user }
                }
                return { logins: [login, ...logins] }
            }),
        delete: (loginId) =>
            set((_state) => {
                return { logins: _state.logins.filter((l) => l.id !== loginId) }
            }),
    },

    versions: [],
    versionsActions: {
        addAll: (versions) =>
            set((_state) => {
                versions.sort(
                    (a, b) =>
                        moment(b.createdAt).unix() - moment(a.createdAt).unix()
                )
                return { versions }
            }),
        add: (version) =>
            set((_state) => {
                const _versions = _state.versions.filter(
                    (l) => l.id !== version.id
                )
                const versions = [version, ..._versions]
                versions.sort(
                    (a, b) =>
                        moment(b.createdAt).unix() - moment(a.createdAt).unix()
                )
                return { versions }
            }),
        update: (versionId, version) =>
            set((_state) => {
                const targetVersion = _state.versions.find(
                    (v) => v.id === versionId
                )

                if (targetVersion) {
                    const _versions = _state.versions.filter(
                        (v) => v.id !== versionId
                    )
                    const _version = {
                        ...targetVersion,
                        description: version.description, //only description can be updated
                    }
                    const versions = [{ ..._version }, ..._versions]
                    versions.sort(
                        (a, b) =>
                            moment(b.createdAt).unix() -
                            moment(a.createdAt).unix()
                    )
                    return { versions }
                }
                return { versions: _state.versions }
            }),
        delete: (versionId) =>
            set((_state) => {
                return {
                    versions: _state.versions.filter((v) => v.id !== versionId),
                }
            }),
    },
}))

/** user hooks */
export const useZsUser = () => useStore((state) => state.user)
export const useZsLogin = async () =>
    useStore((state) => state.userActions.login)
export const useZsLogout = () => useStore((state) => state.userActions.logout)

/** live hooks */
export const useZsLiveData = () => useStore((state) => state.liveData)

export const useZsLiveExtendedData = () =>
    useStore((state) => (state.liveData.length > 0 ? [state.liveData[0]] : []))

export const useZsLiveAddNew = () =>
    useStore((state) => state.liveActions.addNew)
export const useZsLiveAddAll = () =>
    useStore((state) => state.liveActions.addAll)

export const useZsAddAllForHistory = () =>
    useStore((state) => state.liveActions.addAllForHistory)

export const useZsLiveUpdateLast = () =>
    useStore((state) => state.liveActions.updateLast)

/** daily hooks  */
export const useZsDailyData = () => useStore((state) => state.dailyData)
export const useZsDailyAddNew = () =>
    useStore((state) => state.dailyActions.addNew)
export const useZsDailyAddAll = () =>
    useStore((state) => state.dailyActions.addAll)

/** menuItem */
export const useZsActiveMenuItem = () =>
    useStore((state) => state.activeMenuItem)

export const useZsUpdateMenuItem = () =>
    useStore((state) => state.activeMenuItemActions.updateMenu)

/** instance usage */
export const useZsInstanceUsages = () =>
    useStore((state) => state.instanceUsages)

export const useZsInstanceUsagesAddOrUpdate = () =>
    useStore((state) => state.instanceUsagesActions.addOrUpdate)

/** logins */

export const useZsLogins = () => useStore((state) => state.logins)

export const useZsLoginAddAll = () =>
    useStore((state) => state.loginsActions.addAll)
export const useZsLoginAdd = () => useStore((state) => state.loginsActions.add)

export const useZsLoginUpdate = () =>
    useStore((state) => state.loginsActions.update)

export const useZsLoginDelete = () =>
    useStore((state) => state.loginsActions.delete)

/** version control */

export const useZsVersions = () => useStore((state) => state.versions)

export const useZsVersionAddAll = () =>
    useStore((state) => state.versionsActions.addAll)
export const useZsVersionAdd = () =>
    useStore((state) => state.versionsActions.add)

export const useZsVersionUpdate = () =>
    useStore((state) => state.versionsActions.update)

export const useZsVersionDelete = () =>
    useStore((state) => state.versionsActions.delete)
