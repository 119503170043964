import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItems, SHOW_WELCOME_PROFILE } from '../../../../common/constant'
import { useZsUpdateMenuItem, useZsUser } from '../../../../zustand-store'
import { getUser } from '../../../service'
import { getInitials } from '../../../utilities'

export const WelcomeProfile = () => {
    const { t } = useTranslation()
    const [showWelcomeBar, setShowWelcomeBar] = useState(false)
    const loggedUser = useZsUser()

    const changeMenu = useZsUpdateMenuItem()

    const openProfile = async (menu: MenuItems) => {
        const loginData = await getUser(loggedUser?.userId!, 'id')
        changeMenu(menu, loginData!)
    }

    const renderProfileImg = (style: string) => {
        return (
            <div className={style}>
                {loggedUser?.imgData ? (
                    <img
                        className="rounded-full  "
                        src={loggedUser?.imgData}
                        alt=""
                    />
                ) : (
                    <div className="flex items-center justify-center h-16 w-16  bg-[#E5E8EF] rounded-full">
                        <span className="flex items-center content-center text-xxl uppercase font-bold  text-[#959BA5] ">
                            {`${getInitials(loggedUser?.displayName)}`}
                        </span>
                    </div>
                )}
            </div>
        )
    }

    useEffect(() => {
        const profileUserId = window.localStorage.getItem(SHOW_WELCOME_PROFILE)
        if (profileUserId === loggedUser?.userId!) {
            setShowWelcomeBar(false)
        } else {
            setShowWelcomeBar(true)
        }
    }, [])

    return showWelcomeBar ? (
        <div className="bg-white shadow">
            <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                    <div className="min-w-0 flex-1">
                        {/* Profile */}
                        <div className="flex items-center">
                            {renderProfileImg(
                                'hidden bg-white flex justify-center items-center rounded-full h-16 w-16 sm:block'
                            )}
                            <div>
                                <div className="flex items-center">
                                    {renderProfileImg(
                                        ' bg-white flex justify-center items-center rounded-full h-16 w-16  sm:hidden'
                                    )}

                                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                                        {t('Good morning')},{' '}
                                        {loggedUser?.displayName}
                                    </h1>
                                </div>
                                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                    <dt className="sr-only">{t('Company')}</dt>
                                    <dd className="flex items-center text-sm font-medium  text-gray-500 sm:mr-6">
                                        <CheckCircleIcon
                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                            aria-hidden="true"
                                        />
                                        {t(
                                            'You can update your profile image as well as you can update the notification configurations'
                                        )}
                                    </dd>
                                    <dt className="sr-only">{t('Company')}</dt>
                                    <dd className="flex items-center text-sm font-medium  text-gray-500 sm:mr-6">
                                        <div className="flex items-center">
                                            <input
                                                onChange={() => {
                                                    setShowWelcomeBar(false)
                                                    window.localStorage.setItem(
                                                        SHOW_WELCOME_PROFILE,
                                                        loggedUser?.userId!
                                                    )
                                                }}
                                                id="rememberMe"
                                                name="rememberMe"
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"
                                            />
                                            <label
                                                htmlFor="rememberMe"
                                                className="items-center text-sm font-medium  text-gray-500 sm:mr-6"
                                            >
                                                &nbsp;{' '}
                                                {t(
                                                    "Don't show this message again"
                                                )}
                                            </label>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                        <button
                            onClick={() => openProfile(MenuItems.userForm)}
                            type="button"
                            className="inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        >
                            {t('View Profile')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    )
}
