import { useTranslation } from 'react-i18next'
import SpeakupLogo from './common/SpeakupLogo'

const NotFoundPage = (): JSX.Element => {
    const { t } = useTranslation()
    return (
        <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
            <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
                <div className="flex flex-shrink-0 justify-center">
                    <SpeakupLogo fillColor="rgb(8 145 178)" />
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-base font-semibold text-cyan-600">
                            404
                        </p>
                        <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            {t('Page not found')}.
                        </h1>
                        <p className="mt-2 text-base text-gray-500">
                            {t(
                                'Sorry, we couldn’t find the page you’re looking for'
                            )}
                            .
                        </p>
                        <div className="mt-6">
                            <button className="text-base font-medium text-cyan-600 hover:text-cyan-500">
                                {t('Back to home page')}
                                <span aria-hidden="true"> &rarr;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default NotFoundPage
