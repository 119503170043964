import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    ClockIcon,
    DocumentChartBarIcon,
    HomeIcon,
    QuestionMarkCircleIcon,
    UserGroupIcon,
    XMarkIcon,
    MegaphoneIcon,
} from '@heroicons/react/24/outline'
import { useZsUpdateMenuItem, useZsUser } from '../zustand-store'
import { MenuItems } from '../common/constant'
import { useTranslation } from 'react-i18next'
let navigation = [
    { name: 'Home', id: MenuItems.home, icon: HomeIcon, role: 'member' },
    {
        name: 'History',
        id: MenuItems.history,
        icon: ClockIcon,
        role: 'member',
    },
    {
        name: 'Stats',
        id: MenuItems.stats,
        icon: DocumentChartBarIcon,
        role: 'member',
    },
    {
        name: 'Users',
        id: MenuItems.users,
        icon: UserGroupIcon,
        role: 'admin',
    },
    {
        name: 'Mobile Version',
        id: MenuItems.versions,
        icon: MegaphoneIcon,
        role: 'admin',
    },
]

const secondaryNavigation = [
    {
        name: 'Faqs',
        id: MenuItems.faqs,
        icon: QuestionMarkCircleIcon,
        role: 'member',
    },
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}
const Sidebar: React.FC<{ opened: boolean; toggle: Function }> = ({
    opened,
    toggle,
}) => {
    const { t } = useTranslation()
    const user = useZsUser()

    if (!user?.isAdmin) {
        navigation = navigation.filter((n) => n.role === 'member')
    }
    const currentMenu = useRef<MenuItems>(MenuItems.home)

    const changeMenu = useZsUpdateMenuItem()

    const handleChangeMenu = (menu: MenuItems) => {
        currentMenu.current = menu
        changeMenu(menu)
    }

    return (
        <>
            <Transition.Root show={opened} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-40 lg:hidden"
                    onClose={() => {
                        toggle(!opened)
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-cyan-700 pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => {
                                                toggle(!opened)
                                            }}
                                        >
                                            <span className="sr-only">
                                                {t('Close sidebar')}
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex flex-shrink-0 items-center px-4">
                                    <svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 530 1000"
                                        className='"h-3 w-6 fill-white'
                                    >
                                        <path d="M221.60329,1000V589.11313c-38.60519-15.47873-86.04686-54.12973-86.04686-54.12973L9.269,408.51732A26.35884,26.35884,0,0,1,9.001,371.165h0a26.275,26.275,0,0,1,18.58684-7.87449c15.94872.7169,28.83193,11.13836,38.58458,20.91391C83.55222,401.58419,125.5358,450.904,156.82765,450.904s64.77564-63.88238,64.77564-117.33872V76.02479a26.40522,26.40522,0,0,1,52.81044,0V303.05448H296.624L307.62954,25.33987a26.40349,26.40349,0,0,1,52.7651,2.10262l-10.93727,275.612h23.90979L399.44829,52.89832a26.41157,26.41157,0,0,1,52.54064,5.45581L426.46849,303.05448H444.4076l.62482,1.41091,29.99547-166.796a26.39645,26.39645,0,1,1,51.9584,9.34953L483.43835,389.21587c-12.07973,67.10043-28.4082,139.5055-63.68082,178.06717L443.53921,1000Z" />
                                    </svg>
                                </div>
                                <nav
                                    className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                                    aria-label="Sidebar"
                                >
                                    <div className="space-y-1 px-2">
                                        {navigation.map((item) => (
                                            <button
                                                key={item.name}
                                                onClick={() =>
                                                    handleChangeMenu(item.id)
                                                }
                                                className={classNames(
                                                    item.id ===
                                                        currentMenu.current
                                                        ? 'bg-cyan-800 text-white '
                                                        : 'text-cyan-100 hover:text-white hover:bg-cyan-600 ',
                                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md w-full'
                                                )}
                                                aria-current={
                                                    item.id ===
                                                    currentMenu.current
                                                        ? 'page'
                                                        : undefined
                                                }
                                            >
                                                <item.icon
                                                    className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                                                    aria-hidden="true"
                                                />
                                                {t(item.name)}
                                            </button>
                                        ))}
                                    </div>
                                    <div className="mt-6 pt-6">
                                        <div className="space-y-1 px-2">
                                            {secondaryNavigation.map((item) => (
                                                <button
                                                    key={item.name}
                                                    onClick={() =>
                                                        handleChangeMenu(
                                                            item.id
                                                        )
                                                    }
                                                    className={classNames(
                                                        item.id ===
                                                            currentMenu.current
                                                            ? 'bg-cyan-800 text-white '
                                                            : 'text-cyan-100 hover:text-white hover:bg-cyan-600 ',
                                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md w-full'
                                                    )}
                                                    aria-current={
                                                        item.id ===
                                                        currentMenu.current
                                                            ? 'page'
                                                            : undefined
                                                    }
                                                >
                                                    <item.icon
                                                        className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                                                        aria-hidden="true"
                                                    />
                                                    {t(item.name)}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </nav>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-grow flex-col overflow-y-auto bg-cyan-700 pt-5 pb-4 ">
                    <div className="flex flex-shrink-0 items-center px-4">
                        <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 530 1000"
                            className='"h-3 w-6 fill-white'
                        >
                            <path d="M221.60329,1000V589.11313c-38.60519-15.47873-86.04686-54.12973-86.04686-54.12973L9.269,408.51732A26.35884,26.35884,0,0,1,9.001,371.165h0a26.275,26.275,0,0,1,18.58684-7.87449c15.94872.7169,28.83193,11.13836,38.58458,20.91391C83.55222,401.58419,125.5358,450.904,156.82765,450.904s64.77564-63.88238,64.77564-117.33872V76.02479a26.40522,26.40522,0,0,1,52.81044,0V303.05448H296.624L307.62954,25.33987a26.40349,26.40349,0,0,1,52.7651,2.10262l-10.93727,275.612h23.90979L399.44829,52.89832a26.41157,26.41157,0,0,1,52.54064,5.45581L426.46849,303.05448H444.4076l.62482,1.41091,29.99547-166.796a26.39645,26.39645,0,1,1,51.9584,9.34953L483.43835,389.21587c-12.07973,67.10043-28.4082,139.5055-63.68082,178.06717L443.53921,1000Z" />
                        </svg>
                    </div>
                    <nav
                        className="mt-5 flex flex-1 flex-col divide-y divide-cyan-800 overflow-y-auto"
                        aria-label="Sidebar"
                    >
                        <div className="space-y-1 px-2">
                            {navigation.map((item) => (
                                <button
                                    key={item.name}
                                    onClick={() => handleChangeMenu(item.id)}
                                    className={classNames(
                                        item.id === currentMenu.current
                                            ? 'bg-cyan-800 text-white '
                                            : 'text-cyan-100 hover:text-white hover:bg-cyan-600',
                                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md  w-full'
                                    )}
                                    aria-current={
                                        item.id === currentMenu.current
                                            ? 'page'
                                            : undefined
                                    }
                                >
                                    <item.icon
                                        className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                                        aria-hidden="true"
                                    />
                                    {t(item.name)}
                                </button>
                            ))}
                        </div>
                        <div className="mt-6 pt-6">
                            <div className="space-y-1 px-2">
                                {secondaryNavigation.map((item) => (
                                    <button
                                        key={item.name}
                                        onClick={() =>
                                            handleChangeMenu(item.id)
                                        }
                                        className={classNames(
                                            item.id === currentMenu.current
                                                ? 'bg-cyan-800 text-white '
                                                : 'text-cyan-100 hover:text-white hover:bg-cyan-600 ',
                                            'group flex items-center px-2 py-2 text-base font-medium rounded-md w-full'
                                        )}
                                        aria-current={
                                            item.id === currentMenu.current
                                                ? 'page'
                                                : undefined
                                        }
                                    >
                                        <item.icon
                                            className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                                            aria-hidden="true"
                                        />
                                        {t(item.name)}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Sidebar
