import { gql } from '@apollo/client'
import { versionControlFragment } from './fragments'

/**
 * QUERIES
 */
export const VERSIONS_QUERY = gql`
    query VersionsData {
        versionControls {
            ...VersionControlDetail
        }
    }
    ${versionControlFragment}
`
export const VERSION_QUERY = gql`
    query VersionData($id: String) {
        versionControl(where: { id: $id }) {
            ...VersionControlDetail
        }
    }
    ${versionControlFragment}
`

/**
 * MUTATIONS
 */

export const VERSION_CREATE = gql`
    mutation CreateOneVersionControl($data: VersionControlCreateInput!) {
        version: createOneVersionControl(data: $data) {
            ...VersionControlDetail
        }
    }

    ${versionControlFragment}
`

export const VERSION_UPDATE = gql`
    mutation UpdateOneVersionControl(
        $data: VersionControlUpdateInput!
        $where: VersionControlWhereUniqueInput!
    ) {
        version: updateOneVersionControl(data: $data, where: $where) {
            ...VersionControlDetail
        }
    }
    ${versionControlFragment}
`

export const VERSION_DELETE = gql`
    mutation DeleteOneVersionControl($where: VersionControlWhereUniqueInput!) {
        version: deleteOneVersionControl(where: $where) {
            id
        }
    }
`

/**
 * SUBSCRIPTIONS
 */
export const VERSION_UPDATE_SUBSCRIPTION = gql`
    subscription VersionUpdateDataChange($userId: ID!) {
        onVersionUpdate(userId: $userId) {
            ...VersionControlDetail
        }
    }
    ${versionControlFragment}
`

export const VERSION_CREATE_SUBSCRIPTION = gql`
    subscription VersionCreateDataChange($userId: ID!) {
        onVersionCreate(userId: $userId) {
            ...VersionControlDetail
        }
    }
    ${versionControlFragment}
`

export const VERSION_DELETE_SUBSCRIPTION = gql`
    subscription VersionDeleteDataChange($userId: ID!) {
        onVersionDelete(userId: $userId) {
            ...VersionControlDetail
        }
    }
    ${versionControlFragment}
`
