import { LockClosedIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { login } from '../auth'

import SpeakupLogo from './common/SpeakupLogo'
import { useZsLogin } from './zustand-store'

const loginSchema = Yup.object().shape({
    userName: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
})

const LoginForm = () => {
    const history = useHistory()
    const { i18n, t } = useTranslation()

    const currLanguage = i18n.language
    const zsLogin = useZsLogin()

    const [loginError, setLoginError] = useState(false)

    return (
        <>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="mb-5">
                        <SpeakupLogo fillColor="rgb(8 145 178)" />
                    </div>
                    {loginError && (
                        <div className="rounded-md bg-red-50 p-4 mb-5">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <XCircleIcon
                                        className="h-5 w-5 text-red-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">
                                        {t(
                                            `Login to your analytics account was failed`
                                        )}
                                    </h3>
                                    <div className="mt-2 text-sm text-red-700">
                                        <ul className="list-disc space-y-1 pl-5">
                                            <li>
                                                {t(
                                                    `Verify your password or your username`
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Formik
                            initialValues={{
                                userName: '',
                                password: '',
                                rememberMe: true,
                            }}
                            validationSchema={loginSchema}
                            onSubmit={async (values) => {
                                try {
                                    setLoginError(false)
                                    const { userName, password, rememberMe } =
                                        values
                                    const lang = currLanguage
                                        ? currLanguage.substring(0, 2)
                                        : 'en'
                                    const user = await login({
                                        userName,
                                        password,
                                        lang,
                                    })

                                    if (user) {
                                        if (user.resetToken) {
                                            // forward user to change password form
                                            history.push(
                                                { pathname: `/changePassword` },
                                                {
                                                    resetToken: user.resetToken,
                                                }
                                            )
                                        } else {
                                            window.localStorage.setItem(
                                                'SpeakUpRememberMe',
                                                rememberMe ? 'true' : 'false'
                                            )
                                            zsLogin.then((login) => {
                                                login(user)
                                            })
                                        }
                                    } else {
                                        setLoginError(true)
                                    }
                                } catch (error) {
                                    setLoginError(true)
                                }
                            }}
                        >
                            {({ errors, isSubmitting, touched }) => (
                                <Form className="space-y-6">
                                    <div>
                                        <label
                                            htmlFor="userName"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {t('Username or Email address')}
                                        </label>

                                        <div className="mt-1">
                                            <Field
                                                name="userName"
                                                placeholder={t(
                                                    'Username or Email address'
                                                )}
                                                type="text"
                                                className={`${
                                                    errors.userName &&
                                                    touched.userName
                                                        ? 'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm'
                                                        : 'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm '
                                                }`}
                                            />
                                        </div>

                                        {errors.userName &&
                                            touched.userName && (
                                                <p className="mt-2 text-sm text-red-600">
                                                    {t(
                                                        'Username or Email address is required'
                                                    )}
                                                </p>
                                            )}
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="password"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {t(`Password`)}
                                        </label>
                                        <div className="mt-1">
                                            <Field
                                                placeholder={t('Password')}
                                                name="password"
                                                type="password"
                                                className={`${
                                                    errors.password &&
                                                    touched.password
                                                        ? 'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm'
                                                        : 'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm '
                                                }`}
                                            />
                                        </div>
                                        {errors.password &&
                                            touched.password && (
                                                <p className="mt-2 text-sm text-red-600">
                                                    {t(`Password is required`)}
                                                </p>
                                            )}
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <Field
                                                id="rememberMe"
                                                name="rememberMe"
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                                            />
                                            <label
                                                htmlFor="rememberMe"
                                                className="ml-2 block text-sm text-gray-900"
                                            >
                                                {t('Remember me')}
                                            </label>
                                        </div>

                                        <div className="text-sm">
                                            <button
                                                className="font-medium text-cyan-600 hover:text-cyan-500"
                                                type="reset"
                                                onClick={(e) => {
                                                    history.push('/reset')
                                                }}
                                            >
                                                {t('Forgot your password?')}
                                            </button>
                                        </div>
                                    </div>

                                    <div
                                        className={` ${
                                            isSubmitting
                                                ? 'opacity-50'
                                                : 'opacity-100'
                                        } `}
                                    >
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className={` group relative flex w-full justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                                        >
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                <LockClosedIcon
                                                    className="h-5 w-5 text-cyan-500 group-hover:text-cyan-400"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            {t('Sign in')}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LoginForm
