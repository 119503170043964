import { gql } from '@apollo/client'

export const liveUsageFragment = gql`
    fragment LiveUsageDetail on LiveUsage {
        id
        createdAt
        activeUsers
        users
        createdRooms
        extendedOriginData
        extendedThemeData
        extendedOsData
    }
`

export const dailyUsageFragment = gql`
    fragment DailyUsageDetail on DailyUsage {
        id
        avgLiveUsers
        users
        maxLiveUsers
        createdAt
        createdRooms
        extendedOriginData
        extendedThemeData
        extendedOsData
    }
`

export const loginFragment = gql`
    fragment LoginDetail on Login {
        id
        userName
        email
        isNotify
        displayName
        isLdap
        isAdmin
        expired
        createdAt
        updatedAt
        imgData
    }
`

export const instanceUsageFragment = gql`
    fragment InstanceUsageDetail on InstanceUsage {
        createdAt
        instanceUsages
    }
`

export const versionControlFragment = gql`
    fragment VersionControlDetail on VersionControl {
        id
        target
        platform
        versionNbr
        isUpdateRequired
        description
        createdAt
        updatedAt
    }
`
