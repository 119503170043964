import { useTranslation } from 'react-i18next'
import {
    DailyUsageRedis,
    ExtendedCard,
    ExtendedCell,
    ExtendedColors as colors,
    ExtendedData,
} from '../../../../common/constant'
import ExtendedChart from '../../../../common/ExtendedChart'
import { ExtendedAggregationType } from '../../../utilities'

const getCellDataWithColors = (extendedData: Record<string, number>) => {
    const cellData: ExtendedCell[] = []
    const originsColors: string[] = []

    for (const [key, value] of Object.entries(extendedData)) {
        cellData.push({
            name: key,
            value,
        })
        const color = colors[key] ? colors[key] : '#7D4729'
        originsColors.push(color)
    }

    return {
        cellData,
        colors: originsColors,
    }
}

interface Props {
    extendedData: DailyUsageRedis[]
    type: ExtendedAggregationType
}

const getExtendedData = (
    extendedData: DailyUsageRedis[],
    type: ExtendedAggregationType
): ExtendedData | undefined => {
    if (extendedData && extendedData.length > 0) {
        if (type === 'yesterday') {
            return {
                xOr: extendedData[0].xOr,
                xOs: extendedData[0].xOs,
                xTh: extendedData[0].xTh,
            }
        } else {
            const sumExtendedData: ExtendedData = {
                xOr: {},
                xOs: {},
                xTh: {},
            }
            extendedData.forEach((e) => {
                if (e.xOr && Object.keys(e.xOr).length > 0) {
                    for (const [key, value] of Object.entries(e.xOr)) {
                        if (sumExtendedData.xOr.hasOwnProperty(key)) {
                            sumExtendedData.xOr[key] += value
                        } else {
                            sumExtendedData.xOr[key] = value
                        }
                    }
                }

                if (e.xTh && Object.keys(e.xTh).length > 0) {
                    for (const [key, value] of Object.entries(e.xTh)) {
                        if (sumExtendedData.xTh.hasOwnProperty(key)) {
                            sumExtendedData.xTh[key] += value
                        } else {
                            sumExtendedData.xTh[key] = value
                        }
                    }
                }

                if (e.xOs && Object.keys(e.xOs).length > 0) {
                    for (const [key, value] of Object.entries(e.xOs)) {
                        if (sumExtendedData.xOs.hasOwnProperty(key)) {
                            sumExtendedData.xOs[key] += value
                        } else {
                            sumExtendedData.xOs[key] = value
                        }
                    }
                }
            })

            return sumExtendedData
        }
    }
}

const HistoryExtendedCharts: React.FC<Props> = ({ extendedData, type }) => {
    const { t } = useTranslation()
    const myExtendedData = getExtendedData(extendedData, type)

    console.log(
        `HistoryExtendedCharts for ${type} ${JSON.stringify(
            myExtendedData,
            null,
            3
        )}`
    )

    let extendedLiveCharts: ExtendedCard[] = []
    if (myExtendedData) {
        extendedLiveCharts = [
            {
                id: 'Origins',
                name: t('Origin'),
                ...getCellDataWithColors(myExtendedData.xOr),
            },

            {
                id: 'Themes',
                name: t('Theme'),
                ...getCellDataWithColors(myExtendedData.xTh),
            },
            {
                id: 'Operating System',
                name: t('OS / Browser'),
                ...getCellDataWithColors(myExtendedData.xOs),
            },
        ]
    }

    return extendedLiveCharts.length > 0 ? (
        <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {extendedLiveCharts.map((c) => (
                    <ExtendedChart card={c} key={c.id} />
                ))}
            </dl>
        </div>
    ) : (
        <></>
    )
}

export default HistoryExtendedCharts
