import { useQuery, useSubscription } from '@apollo/client'
import { useEffect, useState } from 'react'
import { AppLogger } from '../../../../AppLogger'

import {
    LoginCreateDataChange,
    LoginCreateDataChangeVariables,
} from '../../../../generated/LoginCreateDataChange'
import {
    LoginDeleteDataChange,
    LoginDeleteDataChangeVariables,
} from '../../../../generated/LoginDeleteDataChange'
import { LoginsData } from '../../../../generated/LoginsData'
import {
    LoginUpdateDataChange,
    LoginUpdateDataChangeVariables,
} from '../../../../generated/LoginUpdateDataChange'

import {
    LOGINS_QUERY,
    LOGIN_CREATE_SUBSCRIPTION,
    LOGIN_DELETE_SUBSCRIPTION,
    LOGIN_UPDATE_SUBSCRIPTION,
} from '../../../graphql/queries/login'

import {
    useZsLoginAdd,
    useZsLoginUpdate,
    useZsLoginDelete,
    useZsLoginAddAll,
} from '../../../zustand-store'

const logger = AppLogger.getInstance()

export function useLogins(userId: string) {
    logger.debug(`useUser for userId:${userId}`)
    const [isReady, setReady] = useState(false)

    const zsAddLogins = useZsLoginAddAll()
    const zsAddLogin = useZsLoginAdd()
    const zsUpdateLogin = useZsLoginUpdate()
    const zsDeleteLogin = useZsLoginDelete()

    /** Queries */

    // get all the logins
    const { data, loading } = useQuery<LoginsData>(LOGINS_QUERY, {
        fetchPolicy: 'network-only',
    })

    /**
     * useEffects
     */
    useEffect(() => {
        if (!loading && data) {
            const logins = data.logins ? data.logins : []
            zsAddLogins(logins)
            setReady(true)
        }
        return () => {}
    }, [userId, loading])

    /** Subscriptions */
    useSubscription<LoginCreateDataChange, LoginCreateDataChangeVariables>(
        LOGIN_CREATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onData: async ({ data }) => {
                const login = data.data?.onLoginCreate
                if (login) {
                    logger.debug(
                        `LOGIN_CREATE_SUBSCRIPTION login:${JSON.stringify(
                            login,
                            null,
                            3
                        )}`
                    )
                    zsAddLogin(login)
                }
            },
        }
    )

    useSubscription<LoginUpdateDataChange, LoginUpdateDataChangeVariables>(
        LOGIN_UPDATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onData: async ({ data }) => {
                const login = data.data?.onLoginUpdate
                if (login) {
                    logger.debug(
                        `LOGIN_UPDATE_SUBSCRIPTION login:${JSON.stringify(
                            login,
                            null,
                            3
                        )}`
                    )
                    zsUpdateLogin(login.id, login)
                }
            },
        }
    )

    useSubscription<LoginDeleteDataChange, LoginDeleteDataChangeVariables>(
        LOGIN_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onData: async ({ data }) => {
                const login = data.data?.onLoginDelete
                if (login) {
                    logger.debug(
                        `LOGIN_DELETE_SUBSCRIPTION login:${JSON.stringify(
                            login,
                            null,
                            3
                        )}`
                    )
                    zsDeleteLogin(login.id)
                }
            },
        }
    )

    return {
        data,
        loading: !isReady,
    }
}
