import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts'
import { DailyUsageRedis } from '../../../../common/constant'
import {
    AggregationType,
    formatDataForDailyChart,
    generateRanges,
} from '../../../utilities'

interface Props {
    startRangeDate: Date
    endRangeDate: Date
    data: DailyUsageRedis[]
    aggregationType: AggregationType
}

const HistoryRangeChart: React.FC<Props> = ({
    startRangeDate,
    endRangeDate,
    aggregationType,
    data,
}) => {
    const { t } = useTranslation()
    const ref = useRef<any>()
    const containerOWRef = useRef<number>(1088)
    const [containerWidth, setContainerWidth] = useState(1088)

    const ranges = generateRanges(startRangeDate, endRangeDate, aggregationType)
    const chartData = formatDataForDailyChart(data, aggregationType, ranges)

    useEffect(() => {
        function handleResize() {
            if (
                ref.current &&
                ref.current.offsetWidth !== containerOWRef.current
            ) {
                containerOWRef.current = ref.current.offsetWidth
                setContainerWidth(ref.current.offsetWidth)
            }
        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div
            className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg  "
            ref={ref}
        >
            <BarChart
                width={containerWidth}
                height={250}
                data={chartData}
                margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="ts" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar
                    dataKey="cr"
                    name={t(`Created rooms`)}
                    maxBarSize={15}
                    fill="#82ca9d"
                />
                <Bar
                    dataKey="mxu"
                    name={t(`Max. live users`)}
                    maxBarSize={15}
                    fill="#d884d1"
                />

                <Bar
                    dataKey="sus"
                    name={t(`Total users`)}
                    maxBarSize={15}
                    fill="#8884d8"
                />
            </BarChart>
        </div>
    )
}

export default HistoryRangeChart
