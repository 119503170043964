import { AppLogger } from './AppLogger'
import {
    BASE_URL,
    TOKEN_KEY,
    User,
    USER_KEY,
} from './components/common/constant'

const logger = AppLogger.getInstance()

export function getUser() {
    const _user = localStorage.getItem(USER_KEY)!
    let user: User | null = null
    try {
        if (_user) {
            user = JSON.parse(_user)
        } else {
            logger.info(`User is not connected`)
        }
    } catch (e) {
        logger.error(`Error while decoding JWT token:${_user}`)
    } finally {
        return user
    }
}

export function getAccessToken() {
    logger.debug(`accessTokenKey:${TOKEN_KEY}`)
    return localStorage.getItem(TOKEN_KEY)
}

interface LoginProps {
    reCAPTCHAToken?: string
    userName: string
    password: string
    lang: string
}
export async function login({
    userName,
    password,
    lang,
}: LoginProps): Promise<User | null> {
    const response = await fetch(`${BASE_URL}/login/signin`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            userName,
            password,
            lang,
            hostname: window.location.hostname,
        }),
    })
    if (response.ok) {
        const { accessToken, user } = await response.json()

        if (!user.resetToken) {
            localStorage.setItem(TOKEN_KEY, accessToken)
            localStorage.setItem(USER_KEY, JSON.stringify(user))
        }
        return user
    }
    return null
}

export function logout() {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USER_KEY)
}
