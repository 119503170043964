import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts'
import { LiveUsageChart } from '../../../../common/constant'

interface Props {
    chartData: LiveUsageChart[]
}

const HistoryLiveChart: React.FC<Props> = ({ chartData }) => {
    const { t } = useTranslation()
    console.log(
        `HistoryLiveChart chartData :${JSON.stringify(chartData, null, 3)}`
    )
    const ref = useRef<any>()
    const containerOWRef = useRef<number>(1088)

    const [containerWidth, setContainerWidth] = useState(1088)

    useEffect(() => {
        function handleResize() {
            if (
                ref.current &&
                ref.current.offsetWidth !== containerOWRef.current
            ) {
                containerOWRef.current = ref.current.offsetWidth
                setContainerWidth(ref.current.offsetWidth)
            }
        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div
            className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg  "
            ref={ref}
        >
            <AreaChart
                width={containerWidth}
                height={300}
                data={chartData}
                margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                }}
            >
                <defs>
                    <linearGradient
                        id="colorLiveUsers"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                    >
                        <stop
                            offset="5%"
                            stopColor="#8884d8"
                            stopOpacity={0.8}
                        />
                        <stop
                            offset="95%"
                            stopColor="#8884d8"
                            stopOpacity={0}
                        />
                    </linearGradient>
                    <linearGradient
                        id="colorCreatedRooms"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                    >
                        <stop
                            offset="5%"
                            stopColor="#82ca9d"
                            stopOpacity={0.8}
                        />
                        <stop
                            offset="95%"
                            stopColor="#82ca9d"
                            stopOpacity={0}
                        />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="ts" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />

                <Area
                    name={t('Created rooms')}
                    type="monotone"
                    dataKey="cr"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#colorCreatedRooms)"
                />

                <Area
                    name={t('Live users')}
                    type="monotone"
                    dataKey="au"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill="url(#colorLiveUsers)"
                />
            </AreaChart>
        </div>
    )
}

export default HistoryLiveChart
