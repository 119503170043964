import { useEffect, useRef, useState } from 'react'
import { Switch } from '@headlessui/react'

import { Formik, Form, Field, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import LdapSelectSearch from '../../../../common/LdapSelectSearch'
import PhotoDropzone from '../../../../common/PhotoDropzone'
import { useTranslation } from 'react-i18next'
import { AppLogger } from '../../../../../AppLogger'
import {
    useZsActiveMenuItem,
    useZsLoginDelete,
    useZsLoginUpdate,
    useZsLoginAdd,
    useZsUpdateMenuItem,
    useZsUser,
} from '../../../../zustand-store'
import { LoginData_login } from '../../../../../generated/LoginData'

import {
    createLogin,
    deleteLogin,
    getUser,
    LoginFormValues,
    updateLogin,
} from '../../../service'
import { MenuItems } from '../../../../common/constant'
import { XCircleIcon } from '@heroicons/react/24/outline'
import DestructiveDialog from '../../../../common/DestructiveDialog'
import ConfirmDialog from '../../../../common/ConfirmDialog'

const logger = AppLogger.getInstance()

const passwordRegx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/

export const UserForm = () => {
    const { t } = useTranslation()

    const formikRef = useRef<any>(null)
    const submitBtn = useRef<HTMLButtonElement>(null)
    const user = useZsUser()

    const [openConfirmDel, setOpenConfirmDel] = useState(false)
    const [openConfirmSave, setOpenConfirmSave] = useState(false)

    const zsActiveMenu = useZsActiveMenuItem()
    const changeMenu = useZsUpdateMenuItem()

    const zsAddLogin = useZsLoginAdd()
    const zsUpdateLogin = useZsLoginUpdate()
    const zsDeleteLogin = useZsLoginDelete()

    const updateType =
        user?.userId === zsActiveMenu.menuData?.id ? 'updateProfile' : 'update'

    const handleChangeMenu = (menu: MenuItems) => {
        const targetMenu =
            updateType === 'updateProfile' ? MenuItems.home : menu
        changeMenu(targetMenu)
    }
    let UserSchema = Yup.object().shape({
        id: Yup.string(),
        imgData: Yup.string(),
        isLdap: Yup.boolean(),
        isNotify: Yup.boolean(),
        userName: Yup.string()
            .min(4, t('Too short!'))
            .max(20, t('Too long!'))
            .matches(/^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/)
            .required(t('Required'))
            .test(
                'checkUsernameUnique',
                t('Username is already registered'),
                async function validateValue(value) {
                    try {
                        if (!editMode && value && value.length > 4) {
                            const exist = await getUser(value, 'username')

                            // validation logic
                            return exist && exist.userName ? false : true // or true as you see fit
                        }
                        return true
                    } catch (error) {
                        return false
                    }
                }
            ),
        email: Yup.string()
            .email(t('Email is not valid'))
            .required(t('Required'))
            .test(
                'checkEmailUnique',
                t('Email is already registered'),
                async function validateValue(value) {
                    try {
                        if (!editMode && value) {
                            const exist = await getUser(value, 'email')
                            // validation logic
                            return exist && exist.email ? false : true // or true as you see fit
                        }
                        return true
                    } catch (error) {
                        return false
                    }
                }
            ),
        displayName: Yup.string()
            .min(4, t('Too short!'))
            .max(30, t('Too long!'))
            .required(t('Required')),

        password: Yup.string().when(['id', 'isLdap'], {
            is: (id: string, l: boolean) => {
                if (!l && !id) {
                    logger.log('password is required')
                    return true
                }
                logger.log('password is not required')
                return false
            },
            then: Yup.string()
                .matches(passwordRegx, t('Password is not conform'))
                .required('required')
                .min(8, 'password length min 8'),

            otherwise: Yup.string().notRequired(),
        }),

        confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            t('Password does not match')
        ),
    })

    const [editMode, setEditMode] = useState(!!zsActiveMenu.menuData)
    const [createError, setCreateError] = useState(false)

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    const extractFormValues = (_menuData?: any) => {
        const loginData = (
            _menuData ? _menuData : zsActiveMenu.menuData
        ) as LoginData_login

        return loginData
            ? ({
                  id: loginData.id,
                  userName: loginData.userName,
                  displayName: loginData.displayName,
                  isLdap: loginData.isLdap,
                  email: loginData.email,
                  imgData: loginData.imgData,
                  isNotify: loginData.isNotify,
              } as LoginFormValues)
            : ({
                  id: '',
                  userName: '',
                  password: '',
                  confirmPassword: '',
                  displayName: '',
                  isLdap: true,
                  email: '',
                  imgData: '',
                  isNotify: false,
              } as LoginFormValues)
    }

    const handleSaveB4Leave = () => {
        // inform user,  if he wants to save the changes
        if (submitBtn.current) {
            submitBtn.current.click()
            handleChangeMenu(MenuItems.users)
        }
    }

    const handleDelete = async (id?: string) => {
        if (id) {
            // ask user to confirm the delete
            zsDeleteLogin(id)
            await deleteLogin(id)
            setOpenConfirmDel(false)
            handleChangeMenu(MenuItems.users)
        }
    }

    useEffect(() => {
        if (zsActiveMenu.menuData?.id) {
            const newFormValues = extractFormValues(zsActiveMenu.menuData!)

            if (formikRef.current) {
                formikRef.current.setValues(newFormValues)
                //to reset dirty status
                formikRef.current.resetForm({ values: newFormValues })
                setEditMode(true)
            }
        }

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zsActiveMenu.menuData?.id])

    return (
        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <Formik
                innerRef={formikRef}
                initialValues={extractFormValues()}
                validationSchema={UserSchema}
                onSubmit={async (
                    values: LoginFormValues,
                    actions: FormikHelpers<LoginFormValues>
                ) => {
                    try {
                        setCreateError(false)
                        let _updatedUserForm: LoginFormValues
                        if (editMode) {
                            const updated = await updateLogin(
                                {
                                    id: zsActiveMenu.menuData!.id,
                                    displayName: values.displayName,
                                    isNotify: values.isNotify,
                                    imgData: values.imgData,
                                },
                                updateType
                            )
                            zsUpdateLogin(zsActiveMenu.menuData!.id, updated!)

                            _updatedUserForm = extractFormValues(updated!)
                        } else {
                            const created = await createLogin(values)
                            zsAddLogin(created!)
                            _updatedUserForm = extractFormValues(created!)

                            setEditMode(true)
                        }

                        // Update form values
                        actions.setValues(_updatedUserForm)

                        //to reset dirty status
                        actions.resetForm({ values: _updatedUserForm })
                    } catch (error) {
                        setCreateError(true)
                    }
                }}
            >
                {({
                    values,
                    errors,
                    isSubmitting,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    setValues,
                    isValid,
                    dirty,
                }) => (
                    <Form className="space-y-8 divide-y divide-gray-200">
                        {createError && (
                            <div className="rounded-md bg-red-50 p-4 mt-8">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <XCircleIcon
                                            className="h-5 w-5 text-red-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">
                                            {t(
                                                'There is an error with your submission, please try again later'
                                            )}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="space-y-6 sm:space-y-5">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                                        {t('Profile')}
                                    </h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        {t(
                                            'This information will not be displayed publicly'
                                        )}
                                    </p>
                                </div>

                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="loginType"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t('Login type')}
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <Switch.Group
                                                as="div"
                                                className="flex items-center"
                                            >
                                                <Switch
                                                    disabled={editMode}
                                                    checked={values.isLdap}
                                                    onChange={() =>
                                                        setFieldValue(
                                                            'isLdap',
                                                            !values.isLdap
                                                        )
                                                    }
                                                    className={classNames(
                                                        values.isLdap
                                                            ? 'bg-cyan-600'
                                                            : 'bg-gray-200',
                                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
                                                    )}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            values.isLdap
                                                                ? 'translate-x-5'
                                                                : 'translate-x-0',
                                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                        )}
                                                    />
                                                </Switch>
                                                <Switch.Label
                                                    as="span"
                                                    className="ml-3"
                                                >
                                                    <span className="text-sm font-medium text-gray-900">
                                                        {t('Ldap user')}
                                                    </span>
                                                </Switch.Label>
                                            </Switch.Group>
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="first-name"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t('Display Name')}
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            {values.isLdap && !editMode ? (
                                                <LdapSelectSearch
                                                    editMode={editMode}
                                                    onSelect={(d: any) => {
                                                        setValues({
                                                            ...values,
                                                            ...d,
                                                        })
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    <Field
                                                        disabled={values.isLdap}
                                                        name="displayName"
                                                        type="text"
                                                        className={`${
                                                            errors.displayName &&
                                                            touched.displayName
                                                                ? 'block w-full max-w-lg rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:max-w-xs sm:text-sm'
                                                                : 'block w-full max-w-lg rounded-md border-gray-300 shadow-sm  focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm'
                                                        } `}
                                                    />
                                                    {errors.displayName &&
                                                        touched.displayName && (
                                                            <p
                                                                className="mt-2 text-sm text-red-600"
                                                                id="email-error"
                                                            >
                                                                {t(
                                                                    errors.displayName
                                                                )}
                                                            </p>
                                                        )}
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    {!values.isLdap && !editMode && (
                                        <>
                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label
                                                    htmlFor="first-name"
                                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                >
                                                    {t('Password')}
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <Field
                                                        type="password"
                                                        name="password"
                                                        className={`${
                                                            errors.password &&
                                                            touched.password &&
                                                            touched.confirmPassword
                                                                ? 'block w-full max-w-lg rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:max-w-xs sm:text-sm'
                                                                : 'block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm'
                                                        } `}
                                                    />
                                                    {errors.password &&
                                                        touched.password &&
                                                        touched.confirmPassword && (
                                                            <p
                                                                className="mt-2 text-sm text-red-600"
                                                                id="email-error"
                                                            >
                                                                {t(
                                                                    errors.password
                                                                )}
                                                            </p>
                                                        )}
                                                </div>
                                            </div>

                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label
                                                    htmlFor="first-name"
                                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                                >
                                                    {t('Password Confirm')}
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <Field
                                                        type="password"
                                                        name="confirmPassword"
                                                        className={`${
                                                            errors.confirmPassword &&
                                                            touched.password &&
                                                            touched.confirmPassword
                                                                ? 'block w-full max-w-lg rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:max-w-xs sm:text-sm'
                                                                : 'block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm'
                                                        } `}
                                                    />

                                                    {errors.confirmPassword &&
                                                        touched.password &&
                                                        touched.confirmPassword && (
                                                            <p
                                                                className="mt-2 text-sm text-red-600"
                                                                id="email-error"
                                                            >
                                                                {t(
                                                                    errors.confirmPassword
                                                                )}
                                                            </p>
                                                        )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="first-name"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t('Username')}
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <>
                                                <Field
                                                    disabled={
                                                        editMode ||
                                                        values.isLdap
                                                    }
                                                    name="userName"
                                                    type="text"
                                                    className={`${
                                                        errors.userName &&
                                                        touched.userName
                                                            ? 'block w-full max-w-lg rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:max-w-xs sm:text-sm'
                                                            : 'block w-full max-w-lg rounded-md border-gray-300 shadow-sm  focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm'
                                                    } `}
                                                />
                                                {errors.userName &&
                                                    touched.userName && (
                                                        <p
                                                            className="mt-2 text-sm text-red-600"
                                                            id="email-error"
                                                        >
                                                            {t(errors.userName)}
                                                        </p>
                                                    )}
                                            </>

                                            {errors.userName &&
                                                touched.userName && (
                                                    <p
                                                        className="mt-2 text-sm text-red-600"
                                                        id="email-error"
                                                    >
                                                        {t(errors.userName)}
                                                    </p>
                                                )}
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t('Email address')}
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <Field
                                                disabled={
                                                    values.isLdap || editMode
                                                }
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                className={`${
                                                    errors.email &&
                                                    touched.email
                                                        ? 'block w-full max-w-lg rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:max-w-xs sm:text-sm'
                                                        : 'block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm'
                                                } `}
                                            />

                                            {errors.email && touched.email && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="email-error"
                                                >
                                                    {t(errors.email)}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    {values.imgData && (
                                        <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="photo"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {t('Profile photo')}
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <div className="flex items-center ">
                                                    <span className="h-[4.5rem]  w-[4.5rem] overflow-hidden rounded-full bg-gray-100">
                                                        <img
                                                            className="border-[0.5px] "
                                                            src={
                                                                values.imgData
                                                                    ? values.imgData
                                                                    : process
                                                                          .env
                                                                          .PUBLIC_URL +
                                                                      'images/unisex-avatar.svg'
                                                            }
                                                            alt=""
                                                        />
                                                    </span>

                                                    <button
                                                        onClick={() =>
                                                            setFieldValue(
                                                                'imgData',
                                                                ''
                                                            )
                                                        }
                                                        type="button"
                                                        className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                    >
                                                        {t('Change')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!values.imgData && (
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="cover-photo"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                {t('Upload profile photo')}
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <PhotoDropzone
                                                    loadImg={(imgData: any) =>
                                                        setFieldValue(
                                                            'imgData',
                                                            imgData
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="space-y-6 divide-y divide-gray-200 pt-8 sm:space-y-5 sm:pt-10">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                                        {t('Notifications')}
                                    </h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        {t(
                                            `We'll always let you know about important changes`
                                        )}
                                    </p>
                                </div>
                                <div className="space-y-6 divide-y divide-gray-200 sm:space-y-5">
                                    <div className="pt-6 sm:pt-5">
                                        <div
                                            role="group"
                                            aria-labelledby="label-email"
                                        >
                                            <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                                                <div>
                                                    <div
                                                        className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                                        id="label-email"
                                                    >
                                                        {t('By Email')}
                                                    </div>
                                                </div>
                                                <div className="mt-4 sm:col-span-2 sm:mt-0">
                                                    <div className="max-w-lg space-y-4">
                                                        <div className="relative flex items-start">
                                                            <div className="flex h-5 items-center">
                                                                <Field
                                                                    name="isNotify"
                                                                    type="checkbox"
                                                                    className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                                                                />
                                                            </div>
                                                            <div className="ml-3 text-sm">
                                                                <label
                                                                    htmlFor="comments"
                                                                    className="font-medium text-gray-700"
                                                                >
                                                                    {t(
                                                                        'Get notified when there is an important usage, receive daily and monthly usage reports'
                                                                    )}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <button
                                    onClick={() =>
                                        dirty
                                            ? setOpenConfirmSave(true)
                                            : handleChangeMenu(MenuItems.users)
                                    }
                                    type="button"
                                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                >
                                    {t('Cancel')}
                                </button>

                                {updateType === 'update' && editMode && (
                                    <button
                                        onClick={() => setOpenConfirmDel(true)}
                                        type="button"
                                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    >
                                        {t('Delete')}
                                    </button>
                                )}

                                <button
                                    ref={submitBtn}
                                    disabled={!isValid}
                                    type="submit"
                                    className={`${
                                        isValid ? 'opacity-100' : 'opacity-50'
                                    } ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm bg-cyan-800 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                                >
                                    {t('Save')}
                                </button>
                            </div>
                        </div>
                        {openConfirmSave && (
                            <ConfirmDialog
                                title="Save your changes?"
                                description="Are you sure you want to leave this page and discard your changes?"
                                btnTitle="Save changes"
                                confirmCallbackFn={() =>
                                    isValid
                                        ? handleSaveB4Leave()
                                        : setOpenConfirmSave(false)
                                }
                                cancelCallbackFn={() => {
                                    setOpenConfirmSave(false)
                                    handleChangeMenu(MenuItems.users)
                                }}
                            />
                        )}

                        {openConfirmDel && (
                            <DestructiveDialog
                                title="Delete account"
                                description="Are you sure you want to delete this account?"
                                btnTitle="Delete"
                                confirmCallbackFn={() =>
                                    handleDelete(values.id)
                                }
                                cancelCallbackFn={() => {
                                    setOpenConfirmDel(false)
                                }}
                            />
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}
