import { CalendarDaysIcon } from '@heroicons/react/24/outline'
import { fr, de, enUS } from 'date-fns/locale'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

interface Props {
    handleDateSelection: Function
    selectedDay: Date
}

const DateFilter: React.FC<Props> = ({ selectedDay, handleDateSelection }) => {
    const { i18n } = useTranslation()
    const currLanguage = i18n.language
    const lang = currLanguage ? currLanguage.substring(0, 2) : 'en'

    const getDatePickerLocale = () => {
        let locale = fr
        switch (lang) {
            case 'en':
                locale = enUS
                break
            case 'de':
                locale = de
                break
            default:
                break
        }
        return locale
    }

    return (
        <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="p-5">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <CalendarDaysIcon
                            className="h-6 w-6 text-gray-400"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="ml-2 w-0 flex-1">
                        <DatePicker
                            weekLabel="#"
                            showWeekNumbers
                            dateFormat={'yyyy-MM-dd'}
                            className="h-8  text-center  "
                            locale={getDatePickerLocale()}
                            selected={selectedDay}
                            onSelect={(date) => handleDateSelection(date)} //when day is clicked
                            onChange={(date) => handleDateSelection(date)} //only when value has changed
                            maxDate={moment().subtract(1, 'day').toDate()}
                            minDate={moment().subtract(1, 'month').toDate()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DateFilter
