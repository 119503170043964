import { AppLogger } from '../../../../AppLogger'
import {
    useZsLogins,
    useZsUpdateMenuItem,
    useZsUser,
} from '../../../zustand-store'
import { useLogins } from './useLogins'

import { MenuItems } from '../../../common/constant'
import { getUser } from '../../service'
import { getInitials } from '../../utilities'
import { useTranslation } from 'react-i18next'

export const Users: React.FC<{}> = () => {
    const { t } = useTranslation()
    const user = useZsUser()
    const zsLogins = useZsLogins()

    const changeMenu = useZsUpdateMenuItem()

    const handleChangeMenu = async (menu: MenuItems, loginId: string) => {
        const loginData = await getUser(loginId, 'id')
        changeMenu(menu, loginData!)
    }

    const { loading } = useLogins(user?.userId!)

    console.log(loading)

    return (
        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        {t('Users')}
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {t(
                            'A list of all the users in your account including their name, email, LDAP and notify'
                        )}
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        onClick={() => changeMenu(MenuItems.userForm)}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        {t('Add user')}
                    </button>
                </div>
            </div>
            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                {t('Name')}
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                {t('Username')}
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                            >
                                {t('LDAP')}
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                                {t('Notify')}
                            </th>
                            <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                                <span className="sr-only">{t('Edit')}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {zsLogins.map((person) => (
                            <tr key={person.email}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    <div className="flex items-center">
                                        <div className="h-10 w-10 rounded-full  bg-[#E5E8EF]  flex justify-center items-center flex-shrink-0">
                                            {person?.imgData ? (
                                                <img
                                                    className="rounded-full  "
                                                    src={person?.imgData}
                                                    alt=""
                                                />
                                            ) : (
                                                <span className="flex items-center content-center text-xxl uppercase font-bold  text-[#959BA5]">
                                                    {`${getInitials(
                                                        person?.displayName
                                                    )}`}
                                                </span>
                                            )}
                                        </div>
                                        <div className="ml-4">
                                            <div className="font-medium text-gray-900">
                                                {person.displayName}
                                            </div>
                                            <div className="text-gray-500">
                                                {person.email}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                    {person.userName}
                                </td>
                                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                    {person.isLdap ? t('Yes') : t('No')}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {person.isNotify ? t('Yes') : t('No')}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <button
                                        className="text-cyan-600 hover:text-cyan-900"
                                        onClick={() => {
                                            handleChangeMenu(
                                                MenuItems.userForm,
                                                person.id
                                            )
                                        }}
                                    >
                                        {t('Edit')}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
