import {
    CheckCircleIcon,
    EnvelopeIcon,
    XCircleIcon,
} from '@heroicons/react/20/solid'
import { useState } from 'react'

import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import SpeakupLogo from '../common/SpeakupLogo'

import { requestChangePassword } from './service'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

enum ResetStatus {
    new,
    exist,
    error,
    empty,
}

const ResetForm = () => {
    const { t, i18n } = useTranslation()

    const ResetSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('Email is not valid'))
            .required(t('required')),
    })
    const history = useHistory()
    const [resetStatus, setResetStatus] = useState<ResetStatus>(
        ResetStatus.empty
    )

    const renderResetStatus = (status: ResetStatus) => {
        switch (status) {
            case ResetStatus.new:
            case ResetStatus.exist:
                return (
                    <div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <CheckCircleIcon
                                    className="h-5 w-5 text-green-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-green-800">
                                    {status === ResetStatus.new
                                        ? t(
                                              'New reset password link was created'
                                          )
                                        : t(
                                              'Reset password link already exist'
                                          )}
                                </h3>
                                <div className="mt-2 text-sm text-green-700">
                                    <p>
                                        {t(
                                            'To reset your password, please to access your email and then click on the reset link.'
                                        )}
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <div className="-mx-2 -my-1.5 flex">
                                        <button
                                            onClick={() => history.push('/')}
                                            type="button"
                                            className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                        >
                                            {t('Back to login page')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            case ResetStatus.error:
                return (
                    <div className="rounded-md bg-red-50 p-4 mb-5">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <XCircleIcon
                                    className="h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">
                                    {t(
                                        'Error while trying to create the reset password link'
                                    )}
                                </h3>
                                <div className="mt-2 text-sm text-red-700">
                                    <ul className="list-disc space-y-1 pl-5">
                                        <li>
                                            {t(
                                                'Email is not registered or is not eligible to request a change password'
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            default:
                break
        }
    }
    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <SpeakupLogo
                        fillColor="rgb(8 145 178)"
                        text={t('Reset password')}
                    />
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        {renderResetStatus(resetStatus)}
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={ResetSchema}
                            onSubmit={async ({ email }) => {
                                const currLanguage = i18n.language
                                const lang = currLanguage
                                    ? currLanguage.substring(0, 2)
                                    : 'en'
                                const msgCode = await requestChangePassword(
                                    email,
                                    lang
                                )

                                if (msgCode) {
                                    if (
                                        msgCode === 'PASSWORD_RESET_INSTRUCTION'
                                    ) {
                                        setResetStatus(ResetStatus.new)
                                    } else {
                                        setResetStatus(ResetStatus.exist)
                                    }
                                } else {
                                    setResetStatus(ResetStatus.error)
                                }
                            }}
                        >
                            {({ isSubmitting, errors, touched }) => (
                                <Form className="mt-8 space-y-6">
                                    <div className="-space-y-px rounded-md shadow-sm ">
                                        <label
                                            htmlFor="email-address"
                                            className="sr-only"
                                        >
                                            {t('Email')}
                                        </label>
                                        <Field
                                            id="email-address"
                                            name="email"
                                            type="email"
                                            className={
                                                errors.email && touched.email
                                                    ? 'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm'
                                                    : 'relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm'
                                            }
                                            placeholder={t('Email')}
                                        />

                                        {errors.email && touched.email && (
                                            <p
                                                className="mt-2 text-sm text-red-600"
                                                id="email-error"
                                            >
                                                {t('Email is not valid')}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <button
                                            disabled={isSubmitting}
                                            type="submit"
                                            className={`   group relative flex w-full justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                                        >
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                <EnvelopeIcon
                                                    className="h-5 w-5 text-cyan-500 group-hover:text-cyan-400"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            {t('Send')}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetForm
