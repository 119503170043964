import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/es-us'

import { BrowserRouter } from 'react-router-dom'

import AppRouter from './components/Router'
import { useEffect, useRef } from 'react'
import { getUser } from './auth'
import { useZsLogin } from './components/zustand-store'
import Footer from './components/common/Footer'
import 'react-datepicker/dist/react-datepicker.css'

function App() {
    const zsLogin = useZsLogin()
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    useEffect(() => {
        const user = getUser()
        if (user) {
            timeoutRef.current = setTimeout(() => {
                zsLogin.then((login) => {
                    login(user)
                })
            }, 100)
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
                timeoutRef.current = null
            }
        }
    }, [zsLogin])

    return (
        <BrowserRouter>
            <AppRouter />
            <Footer />
        </BrowserRouter>
    )
}

export default App
