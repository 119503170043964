import moment from 'moment'
import { useZsUser } from '../../../zustand-store'
import { useHome } from './useHome'
import { Cards } from './partials/Cards'
import LiveChart from './partials/LiveChart'
import LiveInstanceUsage from './partials/LiveInstanceUsage'
import { useTranslation } from 'react-i18next'
import ExtendedLiveCharts from './partials/ExtendedLiveCharts'

export const Home = () => {
    const { t } = useTranslation()
    const currDay = moment().format('YYYY-MM-DD')
    const user = useZsUser()
    useHome(user?.userId!)

    return (
        <div className="mt-8">
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                    {t('Live overview')}
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {/* Cards */}
                    <Cards />
                </div>
            </div>

            <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                {t('Live activity')}
            </h2>

            {/* Activity table (small breakpoint and up) */}
            <div>
                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                    <div className="mt-2 flex flex-col">
                        <LiveChart day={currDay} />
                    </div>

                    <div className="mt-2 flex flex-col">
                        <ExtendedLiveCharts />
                    </div>

                    <div className="mt-2 flex flex-col">
                        <LiveInstanceUsage />
                    </div>
                </div>
            </div>
        </div>
    )
}
