import { useTranslation } from 'react-i18next'
import {
    ExtendedCard,
    ExtendedCell,
    ExtendedColors as colors,
} from '../../../../common/constant'
import ExtendedChart from '../../../../common/ExtendedChart'

import { useZsLiveExtendedData } from '../../../../zustand-store'

const getCellDataWithColors = (extendedData: Record<string, number>) => {
    const cellData: ExtendedCell[] = []
    const originsColors: string[] = []

    for (const [key, value] of Object.entries(extendedData)) {
        cellData.push({
            name: key,
            value,
        })
        const color = colors[key] ? colors[key] : '#7D4729'
        originsColors.push(color)
    }

    return {
        cellData,
        colors: originsColors,
    }
}

const ExtendedLiveCharts = () => {
    const { t } = useTranslation()
    const liveExtendedData = useZsLiveExtendedData()

    let extendedLiveCharts: ExtendedCard[] = []
    if (
        liveExtendedData &&
        liveExtendedData[0] &&
        liveExtendedData[0].xOr &&
        liveExtendedData[0].xTh &&
        liveExtendedData[0].xOs
    ) {
        extendedLiveCharts = [
            {
                id: 'Origins',
                name: t('Origin'),
                ...getCellDataWithColors(liveExtendedData[0].xOr),
            },

            {
                id: 'Themes',
                name: t('Theme'),
                ...getCellDataWithColors(liveExtendedData[0].xTh),
            },
            {
                id: 'Operating System',
                name: t('OS / Browser'),
                ...getCellDataWithColors(liveExtendedData[0].xOs),
            },
        ]
    }
    return extendedLiveCharts.length > 0 ? (
        <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {extendedLiveCharts.map((c) => (
                    <ExtendedChart card={c} key={c.id} />
                ))}
            </dl>
        </div>
    ) : (
        <></>
    )
}

export default ExtendedLiveCharts
