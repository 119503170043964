import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'

import { searchInActiveDirectory } from '../main/service'

export interface UserOption {
    readonly value: string
    readonly label: string
    readonly email: string
}

interface Props {
    onSelect: Function
    editMode: boolean
}
const LdapSelectSearch: React.FC<Props> = ({ onSelect, editMode }) => {
    const { t } = useTranslation()
    const handleSelection = (v: any) => {
        onSelect({
            userName: v.value,
            displayName: v.label,
            email: v.email,
        })
    }
    const getOptions = async (query: string): Promise<UserOption[]> => {
        const mem = await searchInActiveDirectory(query)
        const results = mem.map((r) => ({
            value: r.username,
            label: `${r.firstName} ${r.lastName}`,
            ...r,
        }))

        return results
    }

    return (
        <div className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm">
            <AsyncSelect
                isDisabled={editMode}
                loadOptions={getOptions}
                onChange={handleSelection}
                placeholder={t('Search in LDAP ...')}
            />
        </div>
    )
}

export default LdapSelectSearch
