import {
    UserGroupIcon,
    RectangleStackIcon,
    RssIcon,
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useZsLiveData } from '../../../../zustand-store'

interface Card {
    id: string
    name: string
    icon: any
    amount: number
}
const renderCard = (card: Card) => {
    return (
        <div
            key={card.name}
            className="overflow-hidden rounded-lg bg-white shadow"
        >
            <div className="p-5">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        {card.id === 'Live users' ? (
                            <>
                                <span className="h-3 w-3 flex">
                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-cyan-500"></span>
                                    <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-cyan-400 opacity-75"></span>
                                </span>
                            </>
                        ) : (
                            <card.icon
                                className={`h-6 w-6 text-gray-400 `}
                                aria-hidden="true"
                            />
                        )}
                    </div>
                    <div className="ml-5 w-0 flex-1">
                        <dl>
                            <dt className="truncate text-sm font-medium text-gray-500">
                                {card.name}
                            </dt>
                            <dd>
                                <div className="text-lg font-medium text-gray-900">
                                    {card.amount}
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Cards = () => {
    const { t } = useTranslation()
    const liveData = useZsLiveData()

    const cards = [
        {
            id: 'Total users',
            name: t('Total users'),
            icon: UserGroupIcon,
            amount: liveData.length > 0 ? liveData[0].us : 0,
        },

        {
            id: 'Live users',
            name: t('Live users'),
            icon: RssIcon,
            amount: liveData.length > 0 ? liveData[0].au : 0,
        },
        {
            id: 'Created rooms',
            name: t('Created rooms'),
            icon: RectangleStackIcon,
            amount: liveData.length > 0 ? liveData[0].cr : 0,
        },
    ]

    return <> {cards.map((c) => renderCard(c))}</>
}
