import { ExtendedCard, ExtendedCell } from './constant'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

interface Props {
    card: ExtendedCard
}
const ExtendedChart: React.FC<Props> = ({ card }) => {
    const renderCustomizedLabel = ({ index }: any) => {
        const total = card.cellData.reduce(
            (prev: number, cell: ExtendedCell) => {
                return (prev += cell.value)
            },
            0
        )
        const labelText = `${card.cellData[index].name}(${Math.round(
            (card.cellData[index].value * 100) / total
        )}%)`

        return labelText
    }

    return (
        <div
            key={card.name}
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
        >
            <dt className="truncate text-sm font-medium text-gray-500">
                {card.name}
            </dt>

            <dd
                className="text-sm  mt-2  mb-0 font-semibold tracking-tight "
                style={{ height: 140 }}
            >
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                        <Pie
                            data={card.cellData}
                            startAngle={180}
                            endAngle={0}
                            cx="50%"
                            cy="100%"
                            label={renderCustomizedLabel}
                            style={{ fontSize: 9 }}
                            nameKey="name"
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            labelLine
                        >
                            {card.cellData.map((entry, index) => (
                                <>
                                    <Cell
                                        key={`cell-${index}`}
                                        name={`cell-${card.cellData[index].name}`}
                                        fill={
                                            card.colors[
                                                index % card.colors.length
                                            ]
                                        }
                                    />
                                </>
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </dd>
        </div>
    )
}

export default ExtendedChart
