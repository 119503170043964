import { useSubscription } from '@apollo/client'
import { v4 } from 'uuid'
import { AppLogger } from '../../../../AppLogger'
import {
    InstanceUsageDataChange,
    InstanceUsageDataChangeVariables,
} from '../../../../generated/InstanceUsageDataChange'
import {
    UserDailyUsageDataChange,
    UserDailyUsageDataChangeVariables,
    UserDailyUsageDataChange_onDailyUsageCreate,
} from '../../../../generated/UserDailyUsageDataChange'
import {
    UserLiveUsageDataChange,
    UserLiveUsageDataChangeVariables,
    UserLiveUsageDataChange_onLiveUsageCreate,
} from '../../../../generated/UserLiveUsageDataChange'
import { InstanceUsage } from '../../../common/constant'
import {
    DAILY_USAGE_CREATE_SUBSCRIPTION,
    INSTANCE_USAGE_SUBSCRIPTION,
    LIVE_USAGE_CREATE_SUBSCRIPTION,
} from '../../../graphql/queries/usage'
import {
    useZsDailyAddNew,
    useZsInstanceUsagesAddOrUpdate,
    useZsLiveAddNew,
    useZsLiveUpdateLast,
} from '../../../zustand-store'

const logger = AppLogger.getInstance()

export const optimizeLiveUsageRec = (
    lu: UserLiveUsageDataChange_onLiveUsageCreate
) => {
    return {
        id: lu.id,
        au: lu.activeUsers,
        ts: lu.createdAt,
        cr: lu.createdRooms,
        us: lu.users,

        xOr: lu.extendedOriginData,
        xTh: lu.extendedThemeData,
        xOs: lu.extendedOsData,
    }
}

export const optimizeDailyUsageRec = (
    du: UserDailyUsageDataChange_onDailyUsageCreate
) => {
    return {
        id: du.id,
        us: du.users,
        av: du.avgLiveUsers,
        mx: du.maxLiveUsers,
        cr: du.createdRooms,
        ts: du.createdAt,

        xOr: du.extendedOriginData,
        xTh: du.extendedThemeData,
        xOs: du.extendedOsData,
    }
}

export function useHome(userId: string) {
    logger.debug(`useMain for userId:${userId}`)
    const addNewLiveData = useZsLiveAddNew()
    const addNewDailyData = useZsDailyAddNew()

    const instanceUsagesAddOrUpdate = useZsInstanceUsagesAddOrUpdate()

    const updateLiveData = useZsLiveUpdateLast()

    useSubscription<UserLiveUsageDataChange, UserLiveUsageDataChangeVariables>(
        LIVE_USAGE_CREATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onData: async ({ data }) => {
                const liveData = data.data?.onLiveUsageCreate
                if (liveData) {
                    if (liveData.id) {
                        addNewLiveData(optimizeLiveUsageRec(liveData))
                    } else {
                        const tmpId = v4()
                        updateLiveData(
                            optimizeLiveUsageRec({ ...liveData, id: tmpId })
                        )
                    }
                }
            },
        }
    )

    useSubscription<
        UserDailyUsageDataChange,
        UserDailyUsageDataChangeVariables
    >(DAILY_USAGE_CREATE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onData: async ({ data }) => {
            const dailyData = data.data?.onDailyUsageCreate
            if (dailyData) {
                addNewDailyData(optimizeDailyUsageRec(dailyData))
            }
        },
    })

    useSubscription<InstanceUsageDataChange, InstanceUsageDataChangeVariables>(
        INSTANCE_USAGE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onData: async ({ data }) => {
                const instanceData = data.data?.onInstanceUsage
                if (
                    instanceData &&
                    instanceData.instanceUsages &&
                    instanceData.createdAt
                ) {
                    const instanceUsages = {
                        instances: JSON.parse(
                            instanceData.instanceUsages
                        ) as InstanceUsage[],
                        createdAt: instanceData.createdAt as string,
                    }
                    // add to instance usages
                    instanceUsagesAddOrUpdate(instanceUsages)
                }
            },
        }
    )
}
