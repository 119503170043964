import {
    useZsVersions,
    useZsUpdateMenuItem,
    useZsUser,
} from '../../../zustand-store'
import { useVersions } from './useVersions'

import { MenuItems } from '../../../common/constant'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export const Versions: React.FC<{}> = () => {
    const { t } = useTranslation()
    const user = useZsUser()
    const zsVersions = useZsVersions()

    const changeMenu = useZsUpdateMenuItem()

    const handleChangeMenu = (menu: MenuItems, versionId: string) => {
        const versionData = zsVersions.find((v) => v.id === versionId)
        if (versionData) {
            changeMenu(menu, versionData)
        }
    }

    useVersions(user?.userId!)

    return (
        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        {t('Versions')}
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {t(
                            'A list of all published SpeakUp mobile versions including their platform and the description on what is new.'
                        )}
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        onClick={() => changeMenu(MenuItems.versionForm)}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        {t('Publish a new version')}
                    </button>
                </div>
            </div>
            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pr-2"
                            >
                                #
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                {t('Platform')}
                            </th>
                            <th
                                scope="col"
                                className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pr-1"
                            >
                                {t('Requires Update')}
                            </th>

                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pr-3"
                            >
                                {t('Created At')}
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
                            >
                                {t('Description')}
                            </th>
                            <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                                <span className="sr-only">{t('Edit')}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {zsVersions.map((version) => (
                            <tr key={version.id}>
                                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                    V{version.versionNbr}
                                </td>
                                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                    {version.platform}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {version.isUpdateRequired
                                        ? t('Yes')
                                        : t('No')}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {moment(version.createdAt).format(
                                        'YYYY-MM-DD HH:mm'
                                    )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {version.description}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <button
                                        className="text-cyan-600 hover:text-cyan-900"
                                        onClick={() => {
                                            handleChangeMenu(
                                                MenuItems.versionForm,
                                                version.id
                                            )
                                        }}
                                    >
                                        {t('Edit')}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
