import { useTranslation } from 'react-i18next'

const faqs = [
    {
        id: 1,
        question:
            "What's about the collected data? is there any personal data ?",
        answer: `No personal data collected, only when you access or use SpeakUp, the following data is stored on the SpeakUp servers:   - User Identifier: an anonymous identifier is associated with your SpeakUp client (browser, or device). This identifier is required to give users updates about their actions (e.g., posts, likes). It can be deleted by clearing the browser cache or deleting the mobile app. - Usage data: SpeakUp stores user actions such  as when users post messages, like, dislike or delete messages, or provide answers to polls. Each piece of usage data is associated with a timestamp and a user identifier. - User generated data: SpeakUp stores user generated data such as rooms, messages, comments, polls, and votes. Each user generated piece of data is associated with a timestamp and a user identifier. - Nicknames and Moodle: in rooms embedded in Moodle that require nicknames, the Moodle user name will be used as nickname. With this setting, the app loses its anonymity.`,
    },

    {
        id: 2,
        question: "What's the limitation of usage ?",
        answer: `SpeakUp cannot be used for discussions related to illicit activities or any activity against applicable institutional regulations. Users are solely responsible for the comments they post and the rooms they create.`,
    },

    {
        id: 3,
        question: "What's the Community Guidelines?",
        answer: `We do not allow: - Harassment/Bullying - Racism/Discrimination/Hate speech - Offensive language - Spamming - Abuse of security`,
    },

    {
        id: 4,
        question: 'Where the servers located?',
        answer: `SpeakUp servers are based in Switzerland and the information we collect is governed by Swiss law. We may use collected information for academic research purposes.`,
    },

    {
        id: 5,
        question:
            'Do you use Google Analytics or any third-party app for analytics?',
        answer: `SpeakUp does not use Google Analytics or any other third-party application for analysis.`,
    },
    // More questions...
]

const getParts = (str: string) => {
    const parts = str.split(' - ')
    return parts.map((p, i) => (
        <p key={p} className="text-base text-gray-500">
            {i > 0 ? `- ${p}` : p}
        </p>
    ))
}
export default function Faqs() {
    const { t } = useTranslation()
    return (
        <div className="bg-gray-50">
            <div className="mx-auto max-w-7xl divide-y divide-gray-200 py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                    {t('Frequently asked questions')}
                </h2>
                <div className="mt-8">
                    <dl className="divide-y divide-gray-200">
                        {faqs.map((faq) => (
                            <div
                                key={faq.id}
                                className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8"
                            >
                                <dt className="text-base font-medium text-gray-900 md:col-span-5">
                                    {t(faq.question)}
                                </dt>
                                <dd className="mt-2 md:col-span-7 md:mt-0">
                                    {faq.answer.includes(' - ') ? (
                                        getParts(t(faq.answer))
                                    ) : (
                                        <p className="text-base text-gray-500">
                                            {t(faq.answer)}
                                        </p>
                                    )}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
