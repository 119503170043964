import { useTranslation } from 'react-i18next'
export default function Footer() {
    const { i18n } = useTranslation()

    const ln = i18n.language ? i18n.language.substring(0, 2) : 'en'

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    return (
        <footer className={` bg-transparent  w-full bottom-0 hidden sm:block`}>
            <div className="mx-auto max-w-7xl overflow-hidden py-1  px-4 sm:px-6 lg:px-8 ">
                <div className="mt-0 flex justify-center space-x-2">
                    <button
                        className={` hover:text-gray-500 ${
                            ln === 'fr'
                                ? 'text-gray-700 font-bold '
                                : 'text-gray-400'
                        } `}
                        onClick={() => changeLanguage('fr')}
                    >
                        fr
                    </button>
                    <span className="text-gray-400 hover:text-gray-500 ">
                        &nbsp;|
                    </span>
                    <button
                        className={` hover:text-gray-500 ${
                            ln === 'en'
                                ? 'text-gray-700 font-bold'
                                : 'text-gray-400'
                        } `}
                        onClick={() => changeLanguage('en')}
                    >
                        en
                    </button>
                    <span className="text-gray-400 hover:text-gray-500 ">
                        &nbsp;|
                    </span>
                    <button
                        className={` hover:text-gray-500 ${
                            ln === 'de'
                                ? 'text-gray-700 font-bold'
                                : 'text-gray-400'
                        } `}
                        onClick={() => changeLanguage('de')}
                    >
                        de
                    </button>
                </div>
            </div>
        </footer>
    )
}
