/**
 * Used to store the user preferences
 */
export const TOKEN_KEY = 'speakUpAnalyticsAccessToken'
export const USER_KEY = 'speakUpAnalyticsUser'

export const LANGUAGE_KEY = 'speakUpLanguage'
export const THEME_KEY = 'speakUpTheme'

export const SHOW_WELCOME_PROFILE = 'showWelcomeProfile'

export const LANGUAGES: Record<string, string> = {
    French: 'fr',
    English: 'en',
    German: 'de',
}

// for release build the below env variable will be undefined
const isLocalhostLink = process.env['REACT_APP_WEBSOCKETLINK_URI']

export const BASE_URL = isLocalhostLink ? '/speakup/api' : '/analytics/api'

export enum MenuItems {
    home,
    history,
    users,
    userForm,
    stats,
    faqs,
    versions,
    versionForm,
}

export interface Profile {
    rememberMe: boolean
    activeMenuItem: MenuItems
}

export interface User {
    userId: string
    displayName: string
    isNotify: boolean
    resetToken?: string
    imgData?: string
    isAdmin: boolean
}

export interface LiveUsageRedis {
    id: string
    au: number
    ts: string
    cr: number
    us: number

    xOr: Record<string, number>
    xTh: Record<string, number>
    xOs: Record<string, number>
}

export interface DailyUsageRedis {
    id: string
    us: number
    av: number
    mx: number
    cr: number
    ts: string

    xOr: Record<string, number>
    xTh: Record<string, number>
    xOs: Record<string, number>
}

export interface LiveUsageChart {
    au: number
    cr: number
    ts: string
}

export interface DailyUsageChart {
    avu: number
    mxu: number
    cr: number
    ts: string
    sus: number
}

export interface InstanceUsage {
    instance: string
    activeUsers: number
}
export interface InstanceUsages {
    instances: InstanceUsage[]
    createdAt: string
}

/**
 * Extended chart
 */

export interface ExtendedData {
    xOr: Record<string, number>
    xTh: Record<string, number>
    xOs: Record<string, number>
}

export interface ExtendedCell {
    name: string
    value: number
}

export interface ExtendedCard {
    id: string
    name: string
    cellData: ExtendedCell[]
    colors: string[]
}

export const ExtendedColors: Record<string, string> = {
    W: '#0088FE',
    WM: '#00C49F',
    N: '#FFBB28',
    CMD: '#FF8042',

    EPFL: '#056734',
    SpeakUp: '#5050d7',
    UniGE: '#e884bc',
    UniNE: '#0000aa',
    Unil: '#0093d1',
    Analytics: '#D3D3D3',

    iOS: '#5FC9F8',
    Android: '#FECB2E',
    iOS_Android: '#FC3158',

    Chrome: '#dbe4eb',
    Firefox: '#FF9500',
    Safari: '#ABDFF6',
    Opera: '#FFFAF1',
    Edge: '#243292',
}
