import { useTranslation } from 'react-i18next'
import Stat from './Stat'

const Stats: React.FC<{}> = () => {
    const { t } = useTranslation()
    return (
        <>
            <Stat description={t('Yesterday')} aggregationType="daily" />
            <Stat description={t('Last 7 days')} aggregationType="weekly" />
            <Stat description={t('Last 30 days')} aggregationType="monthly" />
        </>
    )
}

export default Stats
