import { Fragment } from 'react'
import { BellIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'
import { useZsLogout, useZsUpdateMenuItem, useZsUser } from '../zustand-store'
import {
    createSubscriptionClient,
    closeSubscriptionClient,
} from '../graphql/apolloClient'
import { MenuItems } from './constant'
import { getUser } from '../main/service'
import { getInitials } from '../main/utilities'
import { useTranslation } from 'react-i18next'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export const Header = () => {
    const { t } = useTranslation()
    const client = createSubscriptionClient()
    const loggedUser = useZsUser()
    const handleLogout = useZsLogout()

    const changeMenu = useZsUpdateMenuItem()

    const openProfile = async (menu: MenuItems) => {
        const loginData = await getUser(loggedUser?.userId!, 'id')
        changeMenu(menu, loginData!)
    }

    return (
        <div className="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
            {/* Search bar */}
            <div className="flex flex-1"></div>
            <div className="ml-4 flex items-center md:ml-6">
                {loggedUser?.isNotify && (
                    <button
                        type="button"
                        className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    >
                        <span className="sr-only">
                            {t('View notifications')}
                        </span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                )}
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                    <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                            <div className="h-10 w-10 rounded-full  bg-[#E5E8EF] flex justify-center items-center ">
                                {loggedUser?.imgData ? (
                                    <img
                                        className="rounded-full  "
                                        src={loggedUser?.imgData}
                                        alt=""
                                    />
                                ) : (
                                    <span className="flex items-center content-center text-xxl uppercase font-bold  text-[#959BA5] ">
                                        {`${getInitials(
                                            loggedUser?.displayName
                                        )}`}
                                    </span>
                                )}
                            </div>
                            <span className="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
                                <span className="sr-only">
                                    {t('Open user menu for')}
                                </span>
                                {loggedUser?.displayName}
                            </span>
                            <ChevronDownIcon
                                className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() =>
                                            openProfile(MenuItems.userForm)
                                        }
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                    >
                                        {t('View Profile')}
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => {
                                            // close the subscription client
                                            // handle the logout
                                            try {
                                                client.close(true, true)
                                                closeSubscriptionClient()
                                                handleLogout()
                                            } catch (error) {}
                                        }}
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                    >
                                        {t('Logout')}
                                    </button>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    )
}
