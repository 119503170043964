import { BASE_URL } from '../common/constant'

export async function requestChangePassword(
    email: string,
    lang: string
): Promise<string | null> {
    const response = await fetch(
        `${BASE_URL}/password-reset/requestChangePassword`,
        {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                email,
                lang,
                hostname: window.location.hostname,
            }),
        }
    )
    if (response.ok) {
        const { code } = await response.json()
        return code
    }

    return null
}

interface ChangePasswordPayload {
    email: string
    resetToken: string
    password: string
    passwordConfirmation: string
}
export async function changePassword(
    cpPayload: ChangePasswordPayload
): Promise<string | null> {
    const response = await fetch(`${BASE_URL}/password-reset/changePassword`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({ ...cpPayload }),
    })
    if (response.ok) {
        const { code } = await response.json()
        return code
    }

    return null
}

export async function validateResetToken(
    email: string,
    resetToken: string
): Promise<boolean> {
    const response = await fetch(
        `${BASE_URL}/password-reset/validateResetToken`,
        {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({ email, resetToken }),
        }
    )
    if (response.ok) {
        const { isValid } = await response.json()
        return isValid
    }

    return false
}
