import moment from 'moment'
import { LoginData_login } from '../../generated/LoginData'
import {
    BASE_URL,
    DailyUsageRedis,
    LiveUsageRedis,
    TOKEN_KEY,
} from '../common/constant'
import { Platform } from '../../generated/globalTypes'
import { VersionData_versionControl } from '../../generated/VersionData'

interface LiveUsageProps {
    day: string
}

export async function getLiveUsageFor(
    props: LiveUsageProps
): Promise<LiveUsageRedis[] | null> {
    const { day = moment().format('YYYY-MM-DD') } = props
    const key = `speakUp%23liveUsage%23data%23${day}`
    const response = await fetch(`${BASE_URL}/redis/lGetKey/${key}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
    })
    if (response.ok) {
        const data = await response.json()
        return data
    }

    return null
}

/**
 *
 * @returns
 */
export async function getDailyUsage(): Promise<DailyUsageRedis[] | null> {
    const key = `speakUp%23dailyUsage%23data`
    const response = await fetch(`${BASE_URL}/redis/lGetKey/${key}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
    })
    if (response.ok) {
        const data = await response.json()
        return data
    }

    return null
}

export interface ADMember {
    firstName: string
    lastName: string
    email: string
    username: string
}
export async function searchInActiveDirectory(
    query: string
): Promise<ADMember[]> {
    const response = await fetch(`${BASE_URL}/login/searchAd`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
        body: JSON.stringify({ query }),
    })
    if (response.ok) {
        const data = await response.json()
        return data
    }

    return []
}

export async function getUser(
    key: string,
    lookType: 'username' | 'id' | 'email'
): Promise<LoginData_login | null> {
    const response = await fetch(`${BASE_URL}/login/pullUser`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
        body: JSON.stringify({ key, lookType: lookType.toString() }),
    })
    if (response.ok) {
        const data = await response.json()
        return data
    }

    return null
}

export async function getVersion(
    key: number,
    platform: string
): Promise<VersionData_versionControl | null> {
    const response = await fetch(`${BASE_URL}/version-control/pullVersion`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
        body: JSON.stringify({ key: String(key), platform }),
    })
    if (response.ok) {
        const data = await response.json()
        return data
    }

    return null
}

export async function getNextVersionNbr(
    platform: string
): Promise<number | null> {
    const response = await fetch(`${BASE_URL}/version-control/nextVersionNbr`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
        body: JSON.stringify({ platform }),
    })
    if (response.ok) {
        const data = await response.json()
        return data.nextVersionNbr
    }

    return null
}
export interface LoginFormValues {
    id?: string
    userName: string
    password?: string
    confirmPassword?: string
    displayName: string
    isLdap: boolean
    isNotify: boolean
    email: string
    imgData: string
}

export interface VersionFormValues {
    id?: string
    versionNbr?: number
    target: string
    platform?: Platform
    isUpdateRequired: boolean
    description: string
}

export async function createLogin(
    data: LoginFormValues
): Promise<LoginData_login | null> {
    const response = await fetch(`${BASE_URL}/login/create`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
        body: JSON.stringify({ ...data }),
    })
    if (response.ok) {
        const data = await response.json()
        return data
    }

    return null
}

interface UpdateLoginPayload {
    id: string
    displayName: string
    isNotify: boolean
    imgData: string
}
export async function updateLogin(
    updateData: UpdateLoginPayload,
    updateType: 'updateProfile' | 'update'
): Promise<LoginData_login | null> {
    const { id, displayName, isNotify, imgData } = updateData
    const response = await fetch(`${BASE_URL}/login/${updateType}`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
        body: JSON.stringify({ id, displayName, isNotify, imgData }),
    })
    if (response.ok) {
        const data = await response.json()
        return data
    }

    return null
}

export async function deleteLogin(id: string): Promise<LoginData_login | null> {
    const response = await fetch(`${BASE_URL}/login/delete`, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
        body: JSON.stringify({ id }),
    })
    if (response.ok) {
        const data = await response.json()
        return data
    }

    return null
}
