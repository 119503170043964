import { useState } from 'react'
import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline'

import { Header } from '../common/Header'
import Sidebar from './DashboardSidebar'
import { Home } from './screens/home/Home'
import { useZsActiveMenuItem } from '../zustand-store'
import { MenuItems } from '../common/constant'
import { History } from './screens/history/History'
import { Users } from './screens/users/Users'
import { useSubscriptionClient } from '../common/hooks/useSubscriptionClient'
import { UserForm } from './screens/users/user/UserForm'
import Stats from './screens/stats/Stats'
import Faqs from './screens/help/Faqs'
import { WelcomeProfile } from './screens/home/partials/WelcomeProfile'
import { useTranslation } from 'react-i18next'
import { Versions } from './screens/versions/Versions'
import { VersionForm } from './screens/versions/version/VersionForm'

export default function Layout() {
    const { t } = useTranslation()
    useSubscriptionClient()
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const zsActiveMenu = useZsActiveMenuItem()

    const renderActiveMenu = () => {
        switch (zsActiveMenu.menuItem) {
            case MenuItems.home:
                return <Home />
            case MenuItems.users:
                return <Users />
            case MenuItems.userForm:
                return <UserForm />
            case MenuItems.versions:
                return <Versions />
            case MenuItems.versionForm:
                return <VersionForm />
            case MenuItems.history:
                return <History />
            case MenuItems.stats:
                return <Stats />
            case MenuItems.faqs:
                return <Faqs />
            default:
                return <>{t('Menu not found')}</>
        }
    }

    return (
        <>
            <Sidebar opened={sidebarOpen} toggle={setSidebarOpen} />
            <div className="min-h-full">
                <div className="flex flex-1 flex-col lg:pl-64">
                    <div className="flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none">
                        <button
                            type="button"
                            className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <span className="sr-only">{t('Open sidebar')}</span>
                            <Bars3CenterLeftIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>
                        <Header />
                    </div>
                    <main className="flex-1 pb-8">
                        {/* Main content */}
                        {zsActiveMenu.menuItem === MenuItems.home && (
                            <WelcomeProfile />
                        )}

                        {renderActiveMenu()}
                    </main>
                </div>
            </div>
        </>
    )
}
